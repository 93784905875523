import Button from 'components/Button';
import DropImageArea from 'components/DropImageArea';
import Editor from 'components/Editor';
import Input from 'components/Input';
import InputForm from 'components/InputForm';
import LayoutTitle from 'components/LayoutTitle';
import Schedule from 'components/Schedule';
import Score from 'components/Score';
import { useToast } from 'components/Toast';
import { useUpdateMerchant } from 'hooks/use-merchants';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import LayoutHeader from 'components/LayoutHeader';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import EmptyModal from 'components/EmptyModal';
import translateLocale from 'lib/translateLocale';
import translateCommercialForm from 'lib/translateCommercialForm';
import webhook from 'lib/webhook.js';
import TranslatorButton from 'components/TranslatorButton';
import translatePlainField from 'lib/translatePlainField';
import translateEditorField from 'lib/translateEditorField';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useSetImageAttributes } from 'hooks/use-media';
import saveAllImages from 'lib/saveAllImages';
import semiStringifyJSON from 'lib/semiStringifyJSON';
import semiParseJSON from 'lib/semiParseJSON';
import { stringifiedEmptySchedule } from 'lib/schedule/emptySchedule';
import handleTranslatedImage from 'lib/handleTranslatedImage';
import Check from 'components/Check';
import { useLazyFullProducts } from 'hooks/use-products';
import { useRevalidateProducts } from 'lib/revalidateProduct';
import FeaturedProducts from 'components/FeaturedProducts';

// ComercialContent subfields that are of type editor
const contentOfTypeEditor = new Set(['description', 'content1', 'content2', 'content3']);

const grantsList = [
	// To add a new grant, the value must increase exponentially
	{
		name: 'Financiado por la Unión Europea',
		value: 1,
	},
	{
		name: 'Plan de Recuperación, Transformación y Resiliencia',
		value: 2,
	},
];

const CommercialForm = ({
	adminLayout,
	className,
	merchantData,
	handleCopyAnotherLanguage = null,
	dirty = false,
	setDirty = () => {},
	withHeader = true,
	getLazyMerchant = () => {},
}) => {
	const { config } = useConf();
	const { locale, user, logOut } = useAuth();
	const disableNonTranslatableFields = locale !== config.default_locale || user.type === 'translator';
	const [update] = useUpdateMerchant();

	// Define constant
	const [emptyCommercialContent] = useState({
		description: '[{"type":"paragraph","children":[{"text":""}]}]',
		title1: '',
		content1: '[{"type":"paragraph","children":[{"text":""}]}]',
		title2: '',
		content2: '[{"type":"paragraph","children":[{"text":""}]}]',
		title3: '',
		content3: '[{"type":"paragraph","children":[{"text":""}]}]',
	});
	const [grants, setGrants] = useState([]);

	const [getFullProducts] = useLazyFullProducts({
		scope: 'active',
		MerchantId: merchantData.id,
	});
	const revalidateProducts = useRevalidateProducts();

	// Counter to increase when loading new data from the API (used to flag the editors
	// to refresh the content)
	const [refreshCounterNumber, setRefreshCounterNumber] = useState(0);

	// Images Upload States
	const [commercialLogo, setCommercialLogo] = useState(null);
	const [featuredImage, setFeaturedImage] = useState(null);
	const [Images, setImages] = useState([]);
	const [isFileUploading, setIsFileUploading] = useState(false);

	// Component States
	const [updateMerchantResponse, setUpdateMerchantResponse] = useState(null);
	const [score, setScore] = useState(0);
	const [fromLocale, setFromLocale] = useState('');
	const [translatorModal, setTranslatorModal] = useState(false);
	// Toast alert
	const { addSuccessMessage, addErrorMessage, addInfoMessage } = useToast();

	const {
		unregister,
		register,
		handleSubmit,
		getValues,
		control,
		setValue,
		watch,
		reset,
		formState: { dirtyFields },
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
		defaultValues: {
			commercialForm: {
				commercialAddress1: '',
				commercialAddress2: '',
				commercialCity: '',
				commercialContent: emptyCommercialContent,
				commercialCountry: 'ES',
				commercialMaps: '',
				commercialName: '',
				commercialPhone: '',
				commercialProvince: '',
				commercialRequiredDescription: '[{"type":"paragraph","children":[{"text":""}]}]',
				commercialSchedule: stringifiedEmptySchedule,
				commercialVideo: '',
				commercialZip: '',
				physicalStore: true,
				CommercialLogoId: null,
				Images: [],
				FeaturedImageId: null,
				grants: 0,
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (merchantData) {
			// Extract JSON and stringify the Editor values (as the Editor expects values stingified)
			const commercialContent =
				semiParseJSON(merchantData.commercialContent, contentOfTypeEditor) || emptyCommercialContent;
			const commercialSchedule = merchantData.commercialSchedule || stringifiedEmptySchedule;

			reset({
				commercialForm: {
					commercialAddress1: merchantData.commercialAddress1 || '',
					commercialAddress2: merchantData.commercialAddress2 || '',
					commercialCity: merchantData.commercialCity || '',
					commercialContent: commercialContent,
					commercialCountry: 'ES',
					commercialMaps: merchantData.commercialMaps || '',
					commercialName: merchantData.commercialName || '',
					commercialPhone: merchantData.commercialPhone || '',
					commercialProvince: merchantData.commercialProvince || '',
					commercialRequiredDescription:
						merchantData.commercialRequiredDescription || '[{"type":"paragraph","children":[{"text":""}]}]',
					commercialSchedule: commercialSchedule,
					commercialVideo: merchantData.commercialVideo || '',
					commercialZip: merchantData.commercialZip || '',
					physicalStore: !!merchantData.physicalStore,
					FeaturedImageId: merchantData.FeaturedImage?.id || null,
					CommercialLogoId: merchantData.CommercialLogo?.id || null,
					Images: merchantData.Images.map(image => image.id) || [],
					grants: merchantData.grants || 0,
				},
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImages(merchantData.Images.map(image => ({ ...image })));
			setCommercialLogo({ ...merchantData.CommercialLogo });
			setFeaturedImage({ ...merchantData.FeaturedImage });
			setDirty(false);
			setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
		}
	}, [merchantData, setImages, setCommercialLogo, setFeaturedImage, setValue, setDirty]);

	// Load Edit data
	useEffect(() => {
		setGrants(
			grantsList.map(item => ({
				name: item.name,
				value: item.value,
				hasGrant: (item.value & merchantData.grants) == item.value || false,
			}))
		);
	}, []);

	const handleGrants = grant => {
		const newGrants = grants.map(prevGrant => {
			return prevGrant.value === grant.value ? { ...grant, hasGrant: !grant.hasGrant } : prevGrant;
		});
		setGrants(newGrants);
		let merchantGrants = 0;
		for (const grantValue of newGrants) {
			if (grantValue.hasGrant) {
				merchantGrants += grantValue.value;
			}
		}
		setValue('commercialForm.grants', merchantGrants);
	};

	// Is used to delete a single image id from commercialForm.Images
	useEffect(() => {
		unregister('commercialForm.Images');
		setValue(
			'commercialForm.Images',
			Images.map(image => image.id)
		);
	}, [Images, setValue, unregister]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (Object.keys(dirtyFields).length > 0) {
			setDirty(true);
		} else {
			setDirty(false);
		}
	}, [Object.keys(dirtyFields).length]);

	// Using calculateFormScore in combination with watch "score" state
	const calculateFormScore = formData => {
		if (formData) {
			const formDataFiltered = [
				...Object.entries(formData).filter(
					e => !['Images', 'commercialAddress2', 'commercialContent', 'commercialSchedule'].includes(e[0])
				),
				...Object.entries(formData.commercialContent),
			];
			let total = formDataFiltered.length;
			let count = 0;
			for (var [, value] of formDataFiltered) {
				if (value && value !== '[{"type":"paragraph","children":[{"text":""}]}]') {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};

	watch(({ commercialForm }, { name }) => {
		calculateFormScore(commercialForm);
		// Validate Google Maps "Embeded a map" share option is an HTML iframe tag
		// E.g.: "<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789636.0604631352!2d2.182125715562589!3d39.367198954049115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa7353bd5f2848f9f%3A0xc9dcbab7027d40a!2sProducto%20de%20Aqu%C3%AD!5e0!3m2!1sen!2ses!4v1667575573282!5m2!1sen!2ses" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>"
		if (name === 'commercialForm.commercialMaps' && commercialForm.commercialMaps.includes('iframe')) {
			const extractedSrc = commercialForm.commercialMaps.match(/( src=")([^"]+)/);
			// The ".match" method returns an array. In this case there must be only 1 "src" in the Google Maps iframe
			if (extractedSrc?.length === 3) {
				// Save only the information of the "src"
				setValue('commercialForm.commercialMaps', extractedSrc[2]);
			}
		}
	});

	// Response update States
	useEffect(async () => {
		if (
			updateMerchantResponse &&
			isSuccessResponse(updateMerchantResponse, ['MerchantUpdate'], logOut, addErrorMessage, 'Ficha Comercial')
		) {
			if (
				updateMerchantResponse.Merchant.commercialName != merchantData.commercialName ||
				updateMerchantResponse.Merchant.FeaturedImage?.id != merchantData.FeaturedImage?.id
			) {
				await getFullProducts({ scope: 'active', MerchantId: merchantData.id }).then(({ data }) => {
					revalidateProducts(config, data.Products.List);
				});
			}
			await saveAllImages([commercialLogo, featuredImage, ...Images], setImageAttributes, locale);
			setDirty(false);
			// If all correct revalidate (refresh) the merchant in the shop
			webhook(
				config,
				config.locales.map(locale => `/${locale}/asociados/${updateMerchantResponse.Merchant.slug}`)
			)
				.then(() => {
					addSuccessMessage(
						'Ficha Comercial',
						`${updateMerchantResponse.Merchant.commercialName} actualizado correctamente`
					);
				})
				.catch(error => {
					addErrorMessage(
						'Ficha Comercial',
						`${updateMerchantResponse.Merchant.commercialName} actualizado correctamente, pero no se pudo revalidar en la tienda: "${error.message}"`
					);
				});
		}
	}, [updateMerchantResponse, addSuccessMessage, addErrorMessage, setDirty]);

	// Handlers
	const handleCommercialLogo = useCallback(
		([newImage]) => {
			setCommercialLogo(newImage);
			setValue('commercialForm.CommercialLogoId', newImage.id);
		},
		[setCommercialLogo, setValue]
	);

	const handleFeaturedImage = useCallback(
		([newImage]) => {
			setFeaturedImage(newImage);
			setValue('commercialForm.FeaturedImageId', newImage.id);
		},
		[setFeaturedImage, setValue]
	);

	const handleImages = useCallback(
		_newData => {
			const prevImages = getValues('commercialForm.Images') || [];
			setValue('commercialForm.Images', [
				...prevImages,
				..._newData.map(image => image.id).filter(imageId => !prevImages.includes(imageId)),
			]);
			setImages(prev => [
				...prev,
				// Add images that are not already added
				..._newData.filter(image => !prev.some(prevImage => prevImage.id === image.id)),
			]);
		},
		[setImages, setValue, getValues]
	);

	const handleTranslateField = async (fieldName, translateMethod, aditionalProp = () => {}) => {
		setTranslatorModal(true);
		getLazyMerchant({
			variables: { id: merchantData.id, forceLocale: true, locale: fromLocale },
		}).then(({ data }) => {
			if (data?.Merchant) {
				if (!data.Merchant[fieldName]) {
					addInfoMessage(
						'Traducción',
						`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
					);
					setTranslatorModal(false);
					return;
				}
				translateMethod(
					data.Merchant[fieldName],
					fromLocale,
					setTranslatorModal,
					setValue,
					`commercialForm.${fieldName}`,
					addErrorMessage,
					locale,
					config,
					aditionalProp
				);
			}
		});
	};

	const handleTranslateCommercialContentField = async (
		fieldName,
		label,
		translateMethod,
		useStringify = false,
		aditionalProp = () => {}
	) => {
		setTranslatorModal(true);
		getLazyMerchant({
			variables: { id: merchantData.id, forceLocale: true, locale: fromLocale },
		}).then(({ data }) => {
			if (data?.Merchant) {
				if (!data.Merchant.commercialContent) {
					addInfoMessage(
						'Traducción',
						`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
					);
					setTranslatorModal(false);
					return;
				}
				try {
					const rawCommercialContent = JSON.parse(data.Merchant.commercialContent);
					const text = useStringify
						? JSON.stringify(rawCommercialContent[fieldName])
						: rawCommercialContent[fieldName];
					if ((useStringify && text === '[{"type":"paragraph","children":[{"text":""}]}]') || !text) {
						addInfoMessage(
							'Traducción',
							`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
						);
						setTranslatorModal(false);
						return;
					}
					translateMethod(
						text,
						fromLocale,
						setTranslatorModal,
						setValue,
						`commercialForm.commercialContent.${fieldName}`,
						addErrorMessage,
						locale,
						config,
						aditionalProp
					);
				} catch {
					setTranslatorModal(false);
					addErrorMessage(`Error al transformar "${label}"`);
				}
			}
		});
	};
	const [setImageAttributes] = useSetImageAttributes();
	const [isSuccessTranslated, setIsSuccessTranslated] = useState(false);
	useEffect(async () => {
		if (isSuccessTranslated) {
			await saveAllImages([commercialLogo, featuredImage, ...Images], setImageAttributes, locale);
		}
	}, [isSuccessTranslated]);

	return (
		<div className={className}>
			<AuthorizationBoundary for={['admin', 'merchant']}>
				<FeaturedProducts merchantId={merchantData.id} />
				<Score data={score} name='comercial' content='' />
			</AuthorizationBoundary>
			{withHeader && (
				<>
					<LayoutHeader>Ficha Comercial</LayoutHeader>
					<p className='my-4'>
						No escribas todo en mayúsculas (solo al inicio de frase), párrafos cortos y revisa la
						ortografía.
					</p>
					<p className='text-gray-700'>Datos que aparecen en la parte pública de la web.</p>
				</>
			)}
			<div className='flex justify-around'>
				<AuthorizationBoundary for={['admin', 'merchant']}>
					{handleCopyAnotherLanguage && (
						<div className='flex justify-center'>
							<Button
								onClick={handleCopyAnotherLanguage}
								type='button'
								tooltip={
									<ul className='list-disc pl-4'>
										<li>Copiará lo escrito en la ficha en Catalán, Español, Inglés o alemán.</li>
										<li>Solo se copiará en los campos vacíos (donde no haya texto).</li>
										<li>Los textos solo se copian, se deben traducir por el Asociado.</li>
									</ul>
								}
							>
								Copiar datos de otro idioma
							</Button>
						</div>
					)}
				</AuthorizationBoundary>
				<div className='flex flex-col ml-auto'>
					<p className='text-center mb-1 text-lg font-medium'>Traducir todo</p>
					<TranslatorButton
						onClick={async () => {
							setIsSuccessTranslated(false);
							const isSuccess = await translateCommercialForm(
								{
									id: merchantData.id,
									...getValues('commercialForm'),
									Images,
									CommercialLogo: commercialLogo,
									FeaturedImage: featuredImage,
								},
								fromLocale,
								locale,
								config,
								setTranslatorModal,
								setValue,
								setRefreshCounterNumber,
								getLazyMerchant,
								setCommercialLogo,
								setFeaturedImage,
								setImages,
								addSuccessMessage,
								addErrorMessage
							);
							if (isSuccess) {
								setDirty(true);
								setIsSuccessTranslated(isSuccess);
							}
						}}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
						tooltip={
							<ul className='list-disc pl-4'>
								{!fromLocale ? (
									<li>Elegir el idioma original del que traducir.</li>
								) : (
									<li>Traducir de {translateLocale(fromLocale)}.</li>
								)}
								<li>No traducirá campos que ya tengan texto en esta ficha.</li>
							</ul>
						}
						title='translate-all'
					/>
				</div>
			</div>
			<br />
			<form
				onSubmit={handleSubmit(({ commercialForm }) => {
					if (
						commercialForm.commercialMaps &&
						!commercialForm.commercialMaps.includes('https://www.google.com/maps/embed')
					) {
						addErrorMessage('Enlace Google Maps incorrecto');
						return;
					}
					update({
						variables: {
							id: merchantData?.id,
							input: {
								...commercialForm,
								commercialContent: semiStringifyJSON(
									commercialForm.commercialContent,
									contentOfTypeEditor
								),
							},
							locale,
						},
					}).then(res => {
						setUpdateMerchantResponse(res.data.MerchantUpdate);
					});
				})}
				encType='multipart/form-data'
			>
				<InputForm
					name='commercialForm.commercialName'
					control={control}
					register={register}
					label='Nombre comercial'
					placeholder='Nombre comercial'
				>
					<TranslatorButton
						onClick={async () => handleTranslateField('commercialName', translatePlainField)}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>
				<DropImageArea
					path={adminLayout ? merchantData.slug : ''}
					label='Logo de empresa'
					whileIdleText='Selecciona logo de la empresa'
					Images={commercialLogo ? [commercialLogo] : []}
					onChange={handleCommercialLogo}
					onDelete={() => {
						setValue('commercialForm.CommercialLogoId', null);
						setCommercialLogo(null);
					}}
					onTranslateImage={translatedImage => handleTranslatedImage(setCommercialLogo, translatedImage)}
					setUploading={setIsFileUploading}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Formato 1/1 (cuadrado)</li>
							<li>Resolución mínima recomendable 190x190px</li>
							<li>Tamaño máximo 500kb</li>
						</ul>
					}
					previewStyle='aspect-square object-contain'
					boxStyle='w-1/3'
					setTranslatorModal={setTranslatorModal}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				/>
				<div className='flex w-full max-w-screen-md '>
					<div className='w-1/3'></div>
					<p className='w-2/3 text-right text-gray-400 italic'>*Entre 450 y 650 caracteres con espacios</p>
				</div>
				<Editor
					name='commercialForm.commercialRequiredDescription'
					control={control}
					label='Descripción'
					placeholder='Descripción'
					required={!adminLayout}
					minLength='450'
					maxLength={locale === 'de' ? '700' : '650'}
					description='Escribe la información más importante en los 160 primeros caracteres. Serán visibles en Google'
					refreshContentNumber={refreshCounterNumber}
					editorHeigth='h-40'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateField(
								'commercialRequiredDescription',
								translateEditorField,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>
				<div className='flex w-full max-w-screen-md '>
					<div className='w-2/3'></div>
					<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 1200 caracteres con espacios</p>
				</div>
				<Editor
					name='commercialForm.commercialContent.description'
					control={control}
					label='Descripción extendida'
					placeholder='Descripción extendida'
					required={!adminLayout}
					maxLength={locale === 'de' ? '1300' : '1200'}
					description='Presentación Empresa'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField(
								'description',
								'Introducción',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>
				<br />
				<p className='my-4'>
					<span className='mb-2 font-semibold text-coral-500 text-xl'>Instrucciones: </span>
					<span>
						No escribas todo en mayúsculas (solo al inicio de frase), párrafos cortos y revisa la
						ortografía.
					</span>
				</p>
				<p>
					Completar los distintos bloques con contenido diferenciado. Por ejemplo, cómo se elaboran los
					productos, historia de la empresa, características de la producción, diferencias...
				</p>
				<br />
				<InputForm
					name='commercialForm.commercialContent.title1'
					control={control}
					register={register}
					label='Título 1'
					placeholder='Título 1'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField('title1', 'Título 1', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>
				<div className='flex w-full max-w-screen-md '>
					<div className='w-1/3'></div>
					<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 3.000 caracteres con espacios</p>
				</div>
				<Editor
					name='commercialForm.commercialContent.content1'
					control={control}
					label='Contenido 1'
					placeholder='Contenido 1'
					maxLength='3000'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField(
								'content1',
								'Contenido 1',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>
				<InputForm
					name='commercialForm.commercialContent.title2'
					control={control}
					register={register}
					label='Título 2'
					placeholder='Título 2'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField('title2', 'Título 2', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>
				<div className='flex w-full max-w-screen-md '>
					<div className='w-1/3'></div>
					<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 3.000 caracteres con espacios</p>
				</div>
				<Editor
					name='commercialForm.commercialContent.content2'
					control={control}
					label='Contenido 2'
					placeholder='Contenido 2'
					maxLength='3000'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField(
								'content2',
								'Contenido 2',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>
				<InputForm
					name='commercialForm.commercialContent.title3'
					control={control}
					register={register}
					label='Título 3'
					placeholder='Título 3'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField('title3', 'Título 3', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>
				<div className='flex w-full max-w-screen-md '>
					<div className='w-1/3'></div>
					<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 3.000 caracteres con espacios</p>
				</div>
				<Editor
					name='commercialForm.commercialContent.content3'
					control={control}
					label='Contenido 3'
					placeholder='Contenido 3'
					maxLength='3000'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateCommercialContentField(
								'content3',
								'Contenido 3',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>
				<br />
				<AuthorizationBoundary for={['admin', 'merchant']}>
					<LayoutTitle>Datos de contacto</LayoutTitle>
					<div className='mb-2 font-semibold text-gray-700 text-lg'>
						Seleccionar "<b>Con</b> tienda física" o "<b>Sin</b> tienda física"
					</div>
					<ul className='list-disc max-w-screen-md ml-4'>
						<li>
							Con tienda física: muestra públicamente mapa con ubicación de la tienda, horarios de
							atención y teléfono de contacto
						</li>
						<li>Sin tienda física: muestra públicamente teléfono de contacto</li>
					</ul>
					<div className='flex mb-6 mt-2'>
						{/* With store button */}
						<Button
							aria-label={`${
								getValues('commercialForm.physicalStore') ? 'true' : 'false'
							} with-physical-store`}
							type='button'
							onClick={() => {
								setValue('commercialForm.physicalStore', true);
							}}
							className={`flex flex-row items-center border-2 ${
								getValues('commercialForm.physicalStore')
									? 'border-blue-600 text-black'
									: 'border-gray-500 text-gray-500'
							}`}
							bg={getValues('commercialForm.physicalStore') ? '' : ''}
							focus=''
							hover=''
						>
							<img
								src={
									getValues('commercialForm.physicalStore')
										? '/images/form-icons/store-blue.svg'
										: '/images/form-icons/store-gray.svg'
								}
								className='h-8 w-8'
							/>
							<p className='ml-2 font-normal text-lg'>
								<b>Con</b> tienda física
							</p>
						</Button>
						{/* Without store button */}
						<Button
							aria-label={`${
								getValues('commercialForm.physicalStore') ? 'false' : 'true'
							} without-physical-store`}
							type='button'
							onClick={() => {
								setValue('commercialForm.physicalStore', false);
							}}
							className={`flex flex-row items-center ml-2 border-2 ${
								getValues('commercialForm.physicalStore')
									? 'border-gray-500 text-gray-500'
									: 'border-blue-600 text-black'
							}`}
							bg=''
							focus=''
							hover=''
						>
							<img
								src={
									getValues('commercialForm.physicalStore')
										? '/images/form-icons/store-block-gray.svg'
										: '/images/form-icons/store-block-blue.svg'
								}
								className='h-8 w-8'
							/>
							<p className='ml-2 font-normal text-lg'>
								<b>Sin</b> tienda física
							</p>
						</Button>
					</div>
					{getValues('commercialForm.physicalStore') && (
						<>
							<div className='flex my-4'>
								<Button
									type='button'
									disabled={!merchantData.administrativeAddress1}
									tooltip={
										!merchantData.administrativeAddress1 ? 'No hay dirección administrativa' : ''
									}
									onClick={() => {
										setValue('commercialForm', {
											...getValues('commercialForm'),
											commercialPhone: merchantData.administrativePhone || '',
											commercialAddress1: merchantData.administrativeAddress1 || '',
											commercialAddress2: merchantData.administrativeAddress2 || '',
											commercialZip: merchantData.administrativeZip || '',
											commercialCity: merchantData.administrativeCity || '',
											commercialProvince: merchantData.administrativeProvince || '',
										});
									}}
								>
									Copiar dirección administrativa
								</Button>
								<Button
									type='button'
									className='ml-4'
									disabled={!merchantData.logisticsAddress1}
									tooltip={!merchantData.logisticsAddress1 ? 'No hay dirección de logística' : ''}
									onClick={() => {
										if (merchantData.administrativeAddress1 !== '') {
											setValue('commercialForm', {
												...getValues('commercialForm'),
												commercialPhone: merchantData.logisticsPhone || '',
												commercialAddress1: merchantData.logisticsAddress1 || '',
												commercialAddress2: merchantData.logisticsAddress2 || '',
												commercialZip: merchantData.logisticsZip || '',
												commercialCity: merchantData.logisticsCity || '',
												commercialProvince: merchantData.logisticsProvince || '',
											});
										}
									}}
								>
									Copiar dirección de logística
								</Button>
							</div>
							<InputForm
								name='commercialForm.commercialAddress1'
								control={control}
								register={register}
								label='Dirección Línea 1'
								placeholder='Dirección Línea 1'
								required={!adminLayout}
								disabled={disableNonTranslatableFields}
							/>
							<InputForm
								name='commercialForm.commercialAddress2'
								control={control}
								register={register}
								label='Dirección Línea 2'
								placeholder='Dirección Línea 2'
								disabled={disableNonTranslatableFields}
							/>
							<InputForm
								name='commercialForm.commercialZip'
								control={control}
								register={register}
								label='Código Postal'
								placeholder='Código Postal'
								pattern='[0-9]*'
								maxLength='5'
								minLength='5'
								required={!adminLayout}
								disabled={disableNonTranslatableFields}
							/>
							<InputForm
								name='commercialForm.commercialCity'
								control={control}
								register={register}
								label='Localidad'
								placeholder='Localidad'
								required={!adminLayout}
								disabled={disableNonTranslatableFields}
							/>
							<InputForm
								name='commercialForm.commercialProvince'
								control={control}
								register={register}
								label='Provincia'
								placeholder='Provincia'
								required={!adminLayout}
								disabled={disableNonTranslatableFields}
							/>
							<Input className='border-none' value='ES' label='País' readOnly />
							<InputForm
								name='commercialForm.commercialMaps'
								control={control}
								register={register}
								label='Enlace Google Maps'
								placeholder='Copiar enlace que aparece en la pestaña "insertar un mapa"'
								disabled={disableNonTranslatableFields}
							/>
							<Schedule
								name='commercialForm.commercialSchedule'
								control={control}
								disabled={disableNonTranslatableFields}
							/>
						</>
					)}
					<InputForm
						name='commercialForm.commercialPhone'
						control={control}
						register={register}
						label='Teléfono'
						placeholder='Teléfono'
						type='tel'
						pattern='^\+?[0-9]*'
						maxLength='15'
						minLength='9'
						disabled={disableNonTranslatableFields}
					/>
				</AuthorizationBoundary>
				<LayoutTitle>Imágenes y video</LayoutTitle>
				<DropImageArea
					path={adminLayout ? merchantData.slug : ''}
					label='Imagen para banner'
					whileIdleText='Selecciona una foto como imagen principal del productor (aparece en la ficha de cada artículo)'
					Images={featuredImage ? [featuredImage] : []}
					onChange={handleFeaturedImage}
					onDelete={() => {
						setValue('commercialForm.FeaturedImageId', null);
						setFeaturedImage(null);
					}}
					onTranslateImage={translatedImage => handleTranslatedImage(setFeaturedImage, translatedImage)}
					setUploading={setIsFileUploading}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Resolución ancho:1903px / alto:585px</li>
							<li>Tamaño máximo 500kb</li>
							<li>
								<a
									href={`${config.statics}/help/croquis_banner_producto.jpg`}
									target='_blank'
									rel='noreferrer'
									className='cursor-pointer underline text-blue-700'
								>
									Ver croquis de la imagen en página del producto
								</a>
							</li>
						</ul>
					}
					previewStyle='aspect-merchantBanner object-cover'
					setTranslatorModal={setTranslatorModal}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				/>
				<DropImageArea
					path={adminLayout ? merchantData.slug : ''}
					label='Galería de la empresa'
					description={
						<ul>
							<li>Agregar breve descripción para ayudar el SEO.</li>
							<li>Las tres primeras imágenes serán las destacadas.</li>
							<li>Puedes utilizar las flechas para reordenarlas.</li>
						</ul>
					}
					Images={Images}
					setImages={setImages}
					MerchantId={merchantData.id}
					galleryType='merchant'
					dirty={dirty}
					setDirty={setDirty}
					onChange={handleImages}
					onDelete={id => setImages(Images => [...Images.filter(i => i?.id !== id)])}
					onTranslateImage={(translatedImage, index) =>
						handleTranslatedImage(setImages, translatedImage, index)
					}
					multiple
					setUploading={setIsFileUploading}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Formato 3/2 (relación ancho / alto)</li>
							<li>Resolución mínima recomendable 608x405px</li>
							<li>Tamaño máximo 500kb</li>
						</ul>
					}
					setTranslatorModal={setTranslatorModal}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				/>
				<p className='font-semibold text-gray-700 text-lg'>Colaboran</p>
				<div className='w-full mb-8 flex flex-col'>
					{grants.map(grant => (
						<div className='flex' key={grant.value}>
							<Check
								className='ml-2 checked:bg-blue-600'
								label={grant.name}
								checked={grant.hasGrant}
								onChange={() => handleGrants(grant)}
								disabled={!adminLayout}
							/>
						</div>
					))}
				</div>
				<AuthorizationBoundary for={['admin', 'merchant']}>
					<InputForm
						name='commercialForm.commercialVideo'
						control={control}
						register={register}
						label='Enlace a Youtube'
						placeholder='Enlace a Youtube'
					/>
				</AuthorizationBoundary>
				<div className='flex gap-10'>
					<Button type='submit' disabled={isFileUploading} className='mt-4'>
						Actualizar ficha comercial
					</Button>
					{merchantData?.id && (
						<Button
							disabled={!merchantData.slug}
							tooltip={
								!merchantData.slug
									? `Actualizar la ficha comercial para poder previsualizarla (falta el nombre comercial en el idioma ${translateLocale(
											locale
									  )})`
									: null
							}
							as={merchantData.slug ? 'a' : 'button'}
							bg='border border-coral-500 text-coral-500 hover:none'
							hover='bg-gray-200'
							className='mt-4'
							target='_blank'
							href={`${config.shop_url}/${locale}/asociados/${merchantData.slug}`}
						>
							PREVISUALIZAR ASOCIADO EN TIENDA
						</Button>
					)}
				</div>
			</form>
			<EmptyModal openModal={translatorModal}>
				<div className='relative flex w-[270px] h-[270px] rounded-full items-center justify-center z-40 overflow-hidden'>
					<div className='absolute flex w-[270px] aspect-square animate-spin-slow bg-gradient-to-r from-black to-coral-500' />
					<div className='flex items-center justify-center bg-coral-500 z-50 w-[220px] h-[220px] rounded-full'>
						<p className='text-5xl font-medium'>Traduciendo</p>
					</div>
				</div>
			</EmptyModal>
		</div>
	);
};

export default CommercialForm;
