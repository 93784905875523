import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import Checkout from 'components/Checkout';
import Input from 'components/Input';
import InputForm from 'components/InputForm';
import LayoutHeader from 'components/LayoutHeader';
import Modal from 'components/Modal';
import Score from 'components/Score';
import SelectForm from 'components/SelectForm';
import TextareaForm from 'components/TextareaForm';
import { useToast } from 'components/Toast';
import { useStripeLoginLink, useStripeStatus, useUpdateMerchant } from 'hooks/use-merchants';
import { useMainProductCategories } from 'hooks/use-product-categories';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import DropDocumentArea from 'components/DropDocumentArea';
import { SectionTitle } from 'components/LayoutTitle';
import Card from 'components/Card';
import isSuccessResponse from 'lib/isSuccessResponse';
import PdfModal from 'components/MerchantContractPdf';
import { merchantCommision, merchantExemptYears } from 'lib/constants/merchantConstants';

const AdministrativeForm = ({
	adminLayout,
	className,
	merchantData,
	userData,
	setDirty = () => {},
	setMeetRequirements,
	withHeader = true,
}) => {
	const { config } = useConf();
	const { locale, user, logOut } = useAuth();
	const disableNonTranslatableFields = locale !== config.default_locale || user.type === 'translator';
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [openModalConditions, setOpenModalConditions] = useState(false);
	const [openModalStripe, setOpenModalStripe] = useState(false);
	const [stripeButtonLabel, setStripeButtonLabel] = useState('');
	const [stripeMessage, setStripeMessage] = useState('');
	const [stripeStatusResponse, setStripeStatusResponse] = useState(null);
	const [score, setScore] = useState(0);
	const [isReadOnly] = useState(!adminLayout);
	const [healthRegistry, setHealthRegistry] = useState(merchantData ? merchantData.HealthRegistry : null);
	const [QualityCertificates, setQualityCertificates] = useState(
		merchantData ? merchantData.QualityCertificates : []
	);
	const [isFileUploading, setIsFileUploading] = useState(false);
	const [stripeLinkLabel, setStripeLinkLabel] = useState(<span>Acceder a plataforma de pagos (Stripe)</span>);

	const [update, { loading }] = useUpdateMerchant({
		onCompleted: ({ MerchantUpdate }) => {
			if (
				isSuccessResponse(MerchantUpdate, ['MerchantUpdate'], logOut, addErrorMessage, 'Ficha Administrativa')
			) {
				setDirty(false);
				if (MerchantUpdate.Url) {
					addSuccessMessage('Ficha Administrativa', 'abriendo Stripe para acabar el proceso de OnBoarding');
					window.open(MerchantUpdate.Url, '_self');
				} else {
					addSuccessMessage('Ficha Administrativa', 'actualizada correctamente');
					// Update form with saved values from db
					setValue('administrativeForm', {
						administrativeAddress1: MerchantUpdate.Merchant.administrativeAddress1 || '',
						administrativeAddress2: MerchantUpdate.Merchant.administrativeAddress2 || '',
						administrativeBusinessName: MerchantUpdate.Merchant.administrativeBusinessName || '',
						administrativeCity: MerchantUpdate.Merchant.administrativeCity || '',
						administrativeCountry: 'ES',
						administrativeDescription: MerchantUpdate.Merchant.administrativeDescription || '',
						administrativeEmail: MerchantUpdate.Merchant.administrativeEmail || '',
						administrativePhone: MerchantUpdate.Merchant.administrativePhone || '',
						administrativeProvince: MerchantUpdate.Merchant.administrativeProvince || '',
						administrativeUrl: MerchantUpdate.Merchant.administrativeUrl || '',
						administrativeVat: MerchantUpdate.Merchant.administrativeVat || '',
						administrativeZip: MerchantUpdate.Merchant.administrativeZip || '',
						acceptConditions: MerchantUpdate.Merchant.acceptConditions || false,
						acceptConditionsAt: MerchantUpdate.Merchant.acceptConditionsAt || null,
						commission: MerchantUpdate.Merchant.commission || 0,
						entranceFee: MerchantUpdate.Merchant.entranceFee || 0,
						healthRegister: MerchantUpdate.Merchant.healthRegister || '',
						logisticsAddress1: MerchantUpdate.Merchant.logisticsAddress1 || '',
						logisticsAddress2: MerchantUpdate.Merchant.logisticsAddress2 || '',
						logisticsCity: MerchantUpdate.Merchant.logisticsCity || '',
						logisticsCountry: 'ES',
						logisticsEmail: MerchantUpdate.Merchant.logisticsEmail || '',
						logisticsPhone: MerchantUpdate.Merchant.logisticsPhone || '',
						logisticsProvince: MerchantUpdate.Merchant.logisticsProvince || '',
						logisticsZip: MerchantUpdate.Merchant.logisticsZip || '',
						meetRequirements: MerchantUpdate.Merchant.meetRequirements || false,
						qualityCertificate: MerchantUpdate.Merchant.qualityCertificate || false,
						ProductCategoryId:
							MerchantUpdate.Merchant.ProductCategoryId || categories?.ProductCategories?.List[0].id,
						QualityCertificates:
							MerchantUpdate.Merchant.QualityCertificates.map(document => document.id) || [],
						HealthRegistry: MerchantUpdate.Merchant.HealthRegistry?.id || null,
					});
					setHealthRegistry(MerchantUpdate.Merchant.HealthRegistry);
					setQualityCertificates(MerchantUpdate.Merchant.QualityCertificates);
					setDirty(false);
				}
			}
		},
	});

	const [getStripeStatus] = useStripeStatus({
		variables: {
			MerchantId: merchantData.id,
		},
		onCompleted: ({ StripeStatus }) => {
			if (isSuccessResponse(StripeStatus, ['StripeStatus'], logOut, addErrorMessage, 'Stripe')) {
				setStripeStatusResponse(StripeStatus);
				setStripeMessage(StripeStatus?.message);
			} else {
				setStripeMessage('Error');
			}
		},
	});

	const [getStripeLoginLink] = useStripeLoginLink({
		onCompleted: ({ StripeLoginLink }) => {
			if (
				isSuccessResponse(
					StripeLoginLink,
					['StripeLoginLink'],
					logOut,
					addErrorMessage,
					'Stripe inicio de sesión'
				)
			) {
				const newWindow = window.open(StripeLoginLink.url);
				newWindow.focus();
				setStripeLinkLabel(<span>Ir a Stripe</span>);
			}
		},
	});

	const { data: categories } = useMainProductCategories();

	const {
		handleSubmit,
		control,
		register,
		setValue,
		getValues,
		watch,
		reset,
		formState: { dirtyFields },
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
		defaultValues: {
			administrativeForm: {
				administrativeAddress1: '',
				administrativeAddress2: '',
				administrativeBusinessName: '',
				administrativeCity: '',
				administrativeCountry: 'ES',
				administrativeDescription: '',
				administrativeEmail: '',
				administrativePhone: '',
				administrativeProvince: '',
				administrativeUrl: '',
				administrativeVat: '',
				administrativeZip: '',
				acceptConditions: false,
				acceptConditionsAt: null,
				commission: 0,
				entranceFee: 0,
				healthRegister: '',
				logisticsAddress1: '',
				logisticsAddress2: '',
				logisticsCity: '',
				logisticsCountry: 'ES',
				logisticsEmail: '',
				logisticsObservations: '',
				logisticsPhone: '',
				logisticsProvince: '',
				logisticsZip: '',
				meetRequirements: false,
				ProductCategoryId: categories?.ProductCategories?.List[0].id,
				qualityCertificate: false,
				QualityCertificates: [],
				HealthRegistry: null,
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (merchantData) {
			reset({
				administrativeForm: {
					administrativeAddress1: merchantData.administrativeAddress1 || '',
					administrativeAddress2: merchantData.administrativeAddress2 || '',
					administrativeBusinessName: merchantData.administrativeBusinessName || '',
					administrativeCity: merchantData.administrativeCity || '',
					administrativeCountry: 'ES',
					administrativeDescription: merchantData.administrativeDescription || '',
					administrativeEmail: merchantData.administrativeEmail || '',
					administrativePhone: merchantData.administrativePhone || '',
					administrativeProvince: merchantData.administrativeProvince || '',
					administrativeUrl: merchantData.administrativeUrl || '',
					administrativeVat: merchantData.administrativeVat || '',
					administrativeZip: merchantData.administrativeZip || '',
					acceptConditions: merchantData.acceptConditions || false,
					acceptConditionsAt: merchantData.acceptConditionsAt || null,
					commission: merchantData.commission || 0,
					entranceFee: merchantData.entranceFee || 0,
					healthRegister: merchantData.healthRegister || '',
					logisticsAddress1: merchantData.logisticsAddress1 || '',
					logisticsAddress2: merchantData.logisticsAddress2 || '',
					logisticsCity: merchantData.logisticsCity || '',
					logisticsCountry: 'ES',
					logisticsEmail: merchantData.logisticsEmail || '',
					logisticsObservations: merchantData.logisticsObservations || '',
					logisticsPhone: merchantData.logisticsPhone || '',
					logisticsProvince: merchantData.logisticsProvince || '',
					logisticsZip: merchantData.logisticsZip || '',
					meetRequirements: merchantData.meetRequirements || false,
					qualityCertificate: merchantData.qualityCertificate || false,
					ProductCategoryId: merchantData.ProductCategoryId || categories?.ProductCategories?.List[0].id,
					QualityCertificates: merchantData.QualityCertificates?.map(document => document.id) || [],
				},
			});
			setHealthRegistry({ ...merchantData.HealthRegistry });
			setQualityCertificates(merchantData.QualityCertificates);
			setDirty(false);
		}
	}, [merchantData, categories, setHealthRegistry, setQualityCertificates, setValue, setDirty]);

	watch(({ administrativeForm }) => {
		calculateFormScore(administrativeForm);
	});

	// Change Stripe button label
	useEffect(() => {
		if (!merchantData?.stripeAccountId) setStripeButtonLabel('Darse de alta en Stripe');
		if (merchantData?.stripeAccountId && userData?.status === 'pending')
			setStripeButtonLabel('Continuar alta en Stripe');
	}, [merchantData, userData]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (Object.keys(dirtyFields).length > 0) {
			setDirty(true);
		} else {
			setDirty(false);
		}
	}, [Object.keys(dirtyFields).length]);

	// Check Stripe Status
	useEffect(async () => {
		if (merchantData) {
			getStripeStatus();
		}
	}, []);

	// Using calculateFormScore in combination with watch "score" state
	const calculateFormScore = formData => {
		if (formData) {
			const formDataFiltered = Object.entries(formData).filter(
				e =>
					![
						'administrativeAddress2',
						'acceptConditionsAt',
						'commission',
						'entranceFee',
						'healthRegister',
						'qualityCertificate',
					].includes(e[0])
			);
			let total = formDataFiltered.length;
			let count = 0;

			for (var [, value] of formDataFiltered) {
				if (value) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};

	const stripeModalObject = {
		title: 'Pago de cuota de adhesión',
		btnText: '',
		cancelBtnText: 'Cerrar',
	};

	const handleHealthRegistry = useCallback(
		([newDocument]) => {
			setHealthRegistry(newDocument);
			setValue('administrativeForm.HealthRegistry', newDocument.id);
		},
		[setHealthRegistry, setValue]
	);

	const handleQualityCertificates = useCallback(
		_newData => {
			const prevQualityCertificates = getValues('administrativeForm.QualityCertificates') || [];
			setValue('administrativeForm.QualityCertificates', [
				...prevQualityCertificates,
				..._newData
					.map(qualityCertificate => qualityCertificate.id)
					.filter(qualityCertificateId => !prevQualityCertificates.includes(qualityCertificateId)),
			]);
			setQualityCertificates(prev => [
				...prev,
				// Add documents that are not already added
				..._newData.filter(
					qualityCertificate =>
						!prev.some(prevQualityCertificate => prevQualityCertificate.id === qualityCertificate.id)
				),
			]);
		},
		[setQualityCertificates, setValue, getValues]
	);

	const handleStripeLoginLink = () => {
		setStripeLinkLabel(
			<span className='flex'>
				Abriendo Stripe
				<div>
					<div className='ml-2 w-4 h-4 border-l-4 border-b-2 border-white border-solid rounded-full animate-spin'></div>
				</div>
			</span>
		);
		getStripeLoginLink();
	};

	const onSubmit = ({ administrativeForm }) => {
		if (administrativeForm) {
			update({
				variables: {
					id: merchantData?.id,
					input: administrativeForm,
					locale,
				},
			});
		}
	};

	const onAcceptConditions = () => {
		update({
			variables: {
				id: merchantData?.id,
				input: getValues('administrativeForm'),
				locale,
			},
		}).then(({ data }) => {
			if (
				isSuccessResponse(
					data?.MerchantUpdate,
					['MerchantUpdate'],
					logOut,
					addErrorMessage,
					'Ficha Administrativa'
				)
			) {
				setDirty(false);
				addSuccessMessage('Ficha Administrativa', 'condiciones de adhesión aceptadas');
			}
		});
	};

	return (
		<div className={className}>
			<Score data={score} name='administrativa' content='' />
			{withHeader && (
				<>
					<LayoutHeader>Ficha Administrativa</LayoutHeader>
					<p className='text-gray-700'>Datos administrativos y de facturación de la empresa.</p>
				</>
			)}
			<br />
			<form onSubmit={handleSubmit(onSubmit)}>
				<SelectForm
					name='administrativeForm.ProductCategoryId'
					control={control}
					register={register}
					label='Categoría principal'
					placeholder='Categoría'
					required={!adminLayout}
					width='w-1/3'
					options={categories?.ProductCategories?.List || []}
					disabled={disableNonTranslatableFields}
				/>
				<InputForm
					name='administrativeForm.administrativeBusinessName'
					control={control}
					register={register}
					label='Razón social'
					placeholder='Razón social'
					disabled={disableNonTranslatableFields}
					readOnly={isReadOnly}
				/>
				<InputForm
					name='administrativeForm.administrativeVat'
					control={control}
					register={register}
					label='CIF'
					placeholder='CIF'
					required={!adminLayout}
					disabled={disableNonTranslatableFields}
					readOnly={isReadOnly}
				/>
				<Card className='mb-4'>
					<SectionTitle>
						Dirección administrativa
						<span className='text-md italic font-normal'> (para facturación)</span>
					</SectionTitle>
					<Card className='mb-2'>
						<InputForm
							name='administrativeForm.administrativeEmail'
							control={control}
							register={register}
							label='Email administrativo'
							placeholder='Email administrativo'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
							specifications={
								<p className='list-disc pl-4'>
									Si cambias el correo administrativo, recuerda que el que usaste inicialmente para tu
									alta en STRIPE no cambiará.
								</p>
							}
						/>
						<InputForm
							name='administrativeForm.administrativePhone'
							control={control}
							register={register}
							label='Teléfono administrativo'
							placeholder='Teléfono administrativo'
							type='tel'
							pattern='^\+?[0-9]*'
							maxLength='15'
							minLength='9'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
						/>
						<InputForm
							name='administrativeForm.administrativeAddress1'
							control={control}
							register={register}
							label='Dirección'
							placeholder='Calle/Avenida/Paseo, Número'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
							readOnly={isReadOnly}
						/>
						<InputForm
							name='administrativeForm.administrativeAddress2'
							control={control}
							register={register}
							label='Más datos de la dirección'
							placeholder='Escalera/Piso/Letra/Empresa/etc.'
							disabled={disableNonTranslatableFields}
							readOnly={isReadOnly}
						/>
						<InputForm
							name='administrativeForm.administrativeZip'
							control={control}
							register={register}
							label='Código postal'
							placeholder='Código postal'
							pattern='[0-9]*'
							maxLength='5'
							minLength='5'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
							readOnly={isReadOnly}
						/>
						<InputForm
							name='administrativeForm.administrativeCity'
							control={control}
							register={register}
							label='Localidad'
							placeholder='Localidad'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
							readOnly={isReadOnly}
						/>
						<InputForm
							name='administrativeForm.administrativeProvince'
							control={control}
							register={register}
							label='Provincia'
							placeholder='Provincia'
							disabled={disableNonTranslatableFields}
							readOnly={isReadOnly}
						/>
						<Input className='border-none' value='ES' label='País' readOnly />
					</Card>
					<SectionTitle>
						Dirección logística
						<span className='text-md italic font-normal'> (recogida de pedidos)</span>
					</SectionTitle>
					<div className='flex mt-4'>
						<Button
							type='button'
							onClick={() => {
								setValue('administrativeForm', {
									...getValues('administrativeForm'),
									logisticsPhone: getValues('administrativeForm.administrativePhone'),
									logisticsEmail: getValues('administrativeForm.administrativeEmail'),
									logisticsAddress1: getValues('administrativeForm.administrativeAddress1'),
									logisticsAddress2: getValues('administrativeForm.administrativeAddress2'),
									logisticsZip: getValues('administrativeForm.administrativeZip'),
									logisticsCity: getValues('administrativeForm.administrativeCity'),
									logisticsProvince: getValues('administrativeForm.administrativeProvince'),
								});
							}}
						>
							Copiar dirección administrativa
						</Button>
						{userData?.status !== 'pending' && (
							<Button
								type='button'
								className='ml-4'
								disabled={!merchantData.commercialAddress1}
								tooltip={
									!merchantData.commercialAddress1
										? 'No hay dirección de tienda en "Ficha Comercial"'
										: ''
								}
								onClick={() => {
									if (merchantData.commercialAddress1 !== '') {
										setValue('administrativeForm', {
											...getValues('administrativeForm'),
											logisticsPhone: merchantData.commercialPhone || '',
											logisticsAddress1: merchantData.commercialAddress1 || '',
											logisticsAddress2: merchantData.commercialAddress2 || '',
											logisticsZip: merchantData.commercialZip || '',
											logisticsCity: merchantData.commercialCity || '',
											logisticsProvince: merchantData.commercialProvince || '',
										});
									}
								}}
							>
								Copiar dirección de tienda
							</Button>
						)}
					</div>
					<Card className='mb-2'>
						<InputForm
							name='administrativeForm.logisticsPhone'
							control={control}
							register={register}
							label='Teléfono para logística'
							placeholder='Teléfono para logística'
							required={!adminLayout}
							type='tel'
							pattern='^\+?[0-9]*'
							maxLength='15'
							minLength='9'
							disabled={disableNonTranslatableFields}
						/>
						<InputForm
							name='administrativeForm.logisticsEmail'
							control={control}
							register={register}
							label='Email para logística'
							placeholder='Email para logística'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
							specifications={
								<p className='list-disc pl-4'>
									Email donde se recibirán las notificaciones de los pedidos, incidencias y
									actualizaciónes en los envíos.
								</p>
							}
						/>
						<InputForm
							name='administrativeForm.logisticsAddress1'
							control={control}
							register={register}
							label='Dirección'
							placeholder='Calle/Avenida/Paseo, Número'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
						/>
						<InputForm
							name='administrativeForm.logisticsAddress2'
							control={control}
							register={register}
							label='Más datos de la dirección'
							placeholder='Escalera/Piso/Letra/Empresa/etc.'
							disabled={disableNonTranslatableFields}
						/>
						<InputForm
							name='administrativeForm.logisticsZip'
							control={control}
							register={register}
							label='Código postal'
							placeholder='Código postal'
							pattern='[0-9]*'
							maxLength='5'
							minLength='5'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
						/>
						<InputForm
							name='administrativeForm.logisticsCity'
							control={control}
							register={register}
							label='Localidad'
							placeholder='Localidad'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
						/>
						<InputForm
							name='administrativeForm.logisticsProvince'
							control={control}
							register={register}
							label='Provincia'
							placeholder='Provincia'
							required={!adminLayout}
							disabled={disableNonTranslatableFields}
						/>
						<Input className='border-none' value='ES' label='País' readOnly />
						<div className='flex w-full max-w-screen-md text-gray-400 italic'>
							<p className='w-full text-right'>*Máximo 500 caracteres con espacios</p>
						</div>
						<TextareaForm
							name='administrativeForm.logisticsObservations'
							control={control}
							disabled={disableNonTranslatableFields}
							maxLength='500'
							label='Observaciones'
							placeholder='Observaciones'
							required={!adminLayout}
						/>
					</Card>
				</Card>

				<InputForm
					name='administrativeForm.administrativeUrl'
					control={control}
					register={register}
					label='Web'
					placeholder='https://ejemplo.com'
					disabled={disableNonTranslatableFields}
					description='https://ejemplo.com'
					regex={/^(http(s)?:\/\/.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)$/}
				/>
				<div className='flex w-full max-w-screen-md text-gray-400 italic'>
					<p className='w-full text-right'>*Máximo 200 caracteres con espacios</p>
				</div>
				<TextareaForm
					name='administrativeForm.administrativeDescription'
					control={control}
					disabled={disableNonTranslatableFields}
					maxLength='200'
					label='Actividad comercial desarrollada'
					placeholder='Actividad comercial desarrollada'
					required={!adminLayout}
				/>
				<InputForm
					name='administrativeForm.healthRegister'
					control={control}
					register={register}
					label='Código registro sanitario'
					placeholder='Código registro sanitario'
					disabled={disableNonTranslatableFields}
					description='(a incluir si se posee)'
				/>
				<DropDocumentArea
					disabled={!merchantData.id}
					disabledMessage='Debes seleccionar un asociado para subir documento'
					MerchantId={merchantData.id}
					label='Registro sanitario'
					Documents={[healthRegistry]}
					onChange={handleHealthRegistry}
					description='Documento en formato .pdf (a incluir si se posee)'
					onDelete={() => {
						setValue('administrativeForm.HealthRegistry', null);
						setHealthRegistry(null);
					}}
					setUploading={setIsFileUploading}
					whileIdleText='Pincha o Suelta tu documento aquí'
				/>
				<DropDocumentArea
					disabled={!merchantData.id}
					disabledMessage='Debes seleccionar un asociado para subir documento'
					MerchantId={merchantData.id}
					label='Certificados de calidad'
					Documents={QualityCertificates}
					onChange={handleQualityCertificates}
					description='Documentos en formato .pdf (a incluir si se posee)'
					onDelete={id => setQualityCertificates(prev => [...prev.filter(i => i.id !== id)])}
					setUploading={setIsFileUploading}
					multiple
				/>
				<br />
				<InputForm
					name='administrativeForm.commission'
					type={adminLayout ? 'number' : 'text'}
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					id='commission'
					label='Comisión'
					htmlFor='commission'
					suffix='%'
					readOnly={isReadOnly}
					description={
						<p className='text-sm font-normal pr-4'>
							% sobre pvp que se descontará del ingreso del asociado
						</p>
					}
				/>
				<InputForm
					name='administrativeForm.entranceFee'
					control={control}
					register={register}
					disabled={disableNonTranslatableFields}
					type={adminLayout ? 'number' : 'text'}
					id='entranceFee'
					label='Cuota adhesión'
					placeholder='Cuota en €'
					htmlFor='entranceFee'
					suffix='€'
					readOnly={isReadOnly}
					description={<p className='text-sm font-normal pr-4'>(más IVA)</p>}
				/>

				<Input
					label='Estado de Stripe'
					value={stripeMessage}
					className={`font-bold text-lg border-none ${
						stripeStatusResponse?.isActive ? 'text-green-400' : 'text-red-500'
					}`}
					readOnly
				/>
				<AuthorizationBoundary for={['merchant']}>
					{stripeStatusResponse?.isActive ? (
						<Button
							onClick={handleStripeLoginLink}
							className='bg-stripe mb-4'
							hover='hover:bg-dark-500'
							type='button'
							focus=''
							aria-label='go-to-stripe'
						>
							{stripeLinkLabel}
						</Button>
					) : null}
				</AuthorizationBoundary>
				<div className='w-full p-4 px-10 py-12 pr-20 shadow-md'>
					<AdhereConditionsMessage
						marketplace_name={config.marketplace_name}
						commission={getValues('administrativeForm.commission')}
					/>
					<div className='flex'>
						<>
							<div className='w-1/2'>
								<CheckForm
									name='administrativeForm.acceptConditions'
									onClick={() => !adminLayout && setOpenModalConditions(true)}
									control={control}
									noToogle={!adminLayout}
									label={
										<p onClick={() => setOpenModalConditions(true)}>
											Acepto las{' '}
											<span className='text-red-500 cursor-pointer'>
												Condiciones de adhesión y servicio.
											</span>
										</p>
									}
									disabled={adminLayout ? false : getValues('administrativeForm.acceptConditions')}
								/>
							</div>
							<AuthorizationBoundary for={['admin']}>
								<CheckForm
									name='administrativeForm.meetRequirements'
									control={control}
									label='Cuota pagada'
								/>
							</AuthorizationBoundary>
							{getValues('administrativeForm.entranceFee') != 0 && (
								<AuthorizationBoundary for={['merchant']}>
									{getValues('administrativeForm.meetRequirements') ? (
										<CheckForm
											name='administrativeForm.meetRequirements'
											control={control}
											label='Cuota pagada'
											disabled={getValues('administrativeForm.meetRequirements')}
										/>
									) : (
										<Button
											onClick={e => {
												e.preventDefault();
												setOpenModalStripe(true);
											}}
											disabled={!getValues('administrativeForm.acceptConditions')}
											tooltip={
												!getValues('administrativeForm.acceptConditions')
													? 'Se requiere aceptar las condiciones de adhesión y actualizar ficha administrativa para guardar los cambios'
													: null
											}
										>
											Pagar cuota de adhesión
										</Button>
									)}
								</AuthorizationBoundary>
							)}
						</>
					</div>
				</div>
				<div className='flex w-full pt-10'>
					<Button type='submit' disabled={isFileUploading}>
						Actualizar ficha administrativa
					</Button>
					<AuthorizationBoundary for={['merchant']}>
						{!stripeStatusResponse?.isActive ? (
							<div className='flex items-center'>
								<Button
									disabled={
										loading ||
										(getValues('administrativeForm.acceptConditions') &&
										getValues('administrativeForm.meetRequirements')
											? false
											: true)
									}
									className='ml-10'
									type='submit'
									tooltip={
										getValues('administrativeForm.acceptConditions') &&
										getValues('administrativeForm.meetRequirements')
											? null
											: `Se requiere aceptar las condiciones de adhesión ${
													getValues('administrativeForm.entranceFee') != 0
														? 'y pago de cuota'
														: ''
											  }`
									}
									onClick={handleSubmit(({ administrativeForm }) => {
										if (administrativeForm) {
											if (
												!adminLayout &&
												administrativeForm.meetRequirements &&
												administrativeForm.acceptConditions
											) {
												setStripeButtonLabel('Abriendo Stripe...');
												update({
													variables: {
														id: merchantData?.id,
														input: administrativeForm,
														locale,
														stripe: true,
													},
												});
											} else if (
												!adminLayout &&
												(administrativeForm.meetRequirements === false ||
													administrativeForm.acceptConditions === false)
											) {
												addErrorMessage(
													'Ficha Administrativa',
													'Debe aceptar condiciones de adhesión y pago de cuota para continuar'
												);
											}
										}
									})}
								>
									{stripeButtonLabel}
								</Button>
								<p className='ml-2'>Plataforma para poder cobrar las ventas</p>
							</div>
						) : null}
					</AuthorizationBoundary>
				</div>
			</form>
			{openModalConditions && (
				<PdfModal
					config={config}
					merchantId={merchantData.id}
					getValues={getValues}
					setValue={setValue}
					openModalConditions={openModalConditions}
					setOpenModalConditions={setOpenModalConditions}
					onAcceptConditions={onAcceptConditions}
				/>
			)}
			<Modal
				modalObject={stripeModalObject}
				openModal={openModalStripe}
				setOpenModal={setOpenModalStripe}
				onCancel={() => setOpenModalStripe(false)}
			>
				<Checkout
					onSuccess={type => {
						setOpenModalStripe(false);
						if (type === 'stripe') {
							setValue('administrativeForm.meetRequirements', true);
							if (setMeetRequirements) {
								setMeetRequirements(true);
							}
						} else if (type === 'transfer') {
							addSuccessMessage(
								'Email',
								'Enviado correctamente, una vez verificado el pago se enviará al correo de tu perfil la factura de mismo'
							);
						}
					}}
					administrativeBusinessName={getValues('administrativeForm.administrativeBusinessName')}
				/>
			</Modal>
		</div>
	);
};

const AdhereConditionsMessage = ({ marketplace_name, commission }) => (
	<>
		<p className='w-full uppercase font-medium text-xl'>Aceptación condiciones de adhesión</p>
		<div className='text-coral-500 border-2 border-coral-500 p-2 m-2'>
			<p className='font-bold'>Por adherirte a {marketplace_name} antes del lanzamiento disfrutas de:</p>
			<ul className='list-disc ml-4 '>
				{commission > merchantCommision[0] && commission <= merchantCommision[12] ? (
					<>
						<li>
							Durante los primeros <span className='font-bold'>{merchantExemptYears[5]}</span> años
							estarás exento del pago de la cuota de mantenimiento anual del 1% de las ventas del año
							anterior (con un máximo de 350 Euros más IVA).
						</li>
					</>
				) : commission <= merchantCommision[15] ? (
					<>
						<li>Un descuento sobre la cuota de adhesión establecida en 1.500 Euros. Ahorras 500 Euros.</li>
						<li>
							Asimismo, durante los primeros <span className='font-bold'>{merchantExemptYears[2]}</span>{' '}
							años estarás exento del pago de la cuota de mantenimiento anual del 1% de las ventas del año
							anterior (con un máximo de 350 Euros más IVA).
						</li>
					</>
				) : null}
				<li>
					Posibilidad de comercializar algunos productos con una{' '}
					<span className='font-bold'>comisión sobre ventas de 0%</span> en el período anterior a la fecha del
					lanzamiento oficial.
				</li>
			</ul>
		</div>
	</>
);

export default AdministrativeForm;
