import React, { useState } from 'react';
import { isBrowser, isMobileSafari } from 'react-device-detect';
import { Page, Text, View, Document, StyleSheet, Font, Image, usePDF } from '@react-pdf/renderer';
import fontSemiBold from '@fontsource/barlow-condensed/files/barlow-condensed-latin-600-normal.woff';
import fontNormal from '@fontsource/barlow-condensed/files/barlow-condensed-latin-400-normal.woff';
import fontItalic from '@fontsource/barlow-condensed/files/barlow-condensed-latin-400-italic.woff';
import Currency from './Currency';
import formatDate, { formatDateYYYYMMDD } from 'lib/formatDate';
import formatTime from 'lib/formatTime';
import PulseLoader from './PulseLoader';
import Button from './Button';
import { useEffect } from 'react';
import Modal from './Modal';
import { useUploadMerchantContract } from 'hooks/use-documents';
import isSuccessResponse from 'lib/isSuccessResponse';
import useAuth from 'hooks/use-auth';
import { useToast } from './Toast';

// Register font
Font.register({
	family: 'Barlow Condensed',
	fonts: [{ src: fontNormal }, { src: fontItalic, fontStyle: 'italic' }, { src: fontSemiBold, fontWeight: 600 }],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		fontFamily: 'Barlow Condensed',
		flexDirection: 'column',
		backgroundColor: '#FFF',
		width: '100%',
		fontSize: '12',
		paddingBottom: 70,
	},
});

const PdfModal = ({
	config,
	merchantId,
	getValues,
	setValue,
	openModalConditions,
	setOpenModalConditions,
	onAcceptConditions,
}) => {
	const { logOut } = useAuth();
	const { addErrorMessage } = useToast();
	const merchant = getValues('administrativeForm');
	const acceptedConditions = merchant.acceptConditions;

	const [documentToSave, setDocumentToSave] = useState(null);
	const [uploadMerchantContract] = useUploadMerchantContract({
		onCompleted: ({ UploadMerchantContract }) => {
			if (isSuccessResponse(UploadMerchantContract, ['Success'], logOut, addErrorMessage, 'Contrato')) {
				if (!getValues('administrativeForm.acceptConditions')) {
					setValue('administrativeForm.acceptConditions', true);
					onAcceptConditions();
				}
				setOpenModalConditions(false);
			}
		},
	});

	const conditionsModalObject = {
		title: 'Condiciones de adhesión',
		btnText: getValues('administrativeForm.acceptConditions') ? '' : 'Aceptar',
		cancelBtnText: getValues('administrativeForm.acceptConditions') ? 'Cerrar' : 'Volver',
	};

	return (
		<Modal
			modalObject={conditionsModalObject}
			openModal={openModalConditions}
			setOpenModal={setOpenModalConditions}
			handleSubmit={() => {
				uploadMerchantContract({ variables: { document: documentToSave, MerchantId: merchantId } });
			}}
			onCancel={() => {
				setOpenModalConditions(false);
				if (!getValues('administrativeForm.acceptConditions')) {
					setValue('administrativeForm.acceptConditions', false);
				}
			}}
			fullScreen
		>
			{/* If the merchant has acceptedConditions, load the PDF from a url in the FTP, else generate de PDF to display and upload on accept */}
			{acceptedConditions ? (
				<PdfDisplay
					src={`${config.statics}/contracts/${merchantId}/contrato_${formatDateYYYYMMDD(
						merchant.acceptConditionsAt,
						'_'
					)}.pdf`}
				/>
			) : (
				<GenerateMerchantContractPdf
					config={config}
					setDocumentToSave={setDocumentToSave}
					merchant={merchant}
					merchantId={merchantId}
				/>
			)}
		</Modal>
	);
};

const PdfDisplay = ({ src }) =>
	isBrowser && !isMobileSafari ? (
		<iframe
			id='contract'
			title='PDF in an i-Frame'
			src={src}
			frameBorder='1'
			scrolling='auto'
			height='100%'
			width='100%'
		></iframe>
	) : (
		<div className='flex justify-center items-center w-full h-full mt-4 lg:mt-0'>
			<a href={src} target='_blank' type='application/pdf' rel='noreferrer'>
				<Button className='mt-4 lg:mt-0'>Abrir PDF en nueva ventana</Button>
			</a>
		</div>
	);

const GenerateMerchantContractPdf = ({ config, setDocumentToSave, merchant }) => {
	const [instance] = usePDF({
		document: <MerchantContractPdf config={config} merchant={merchant} />,
	});

	useEffect(async () => {
		if (!instance?.loading && instance.blob) {
			const arrayBuffer = await instance?.blob?.arrayBuffer();
			// Transform arrayBuffer to a base64 string to send to API
			var base64String = Buffer.from(arrayBuffer, 'base64').toString('base64');
			setDocumentToSave(base64String);
		}
	}, [instance?.loading]);

	if (instance.loading)
		return (
			<div className='flex justify-center items-center w-full h-full mt-4 lg:mt-0'>
				<p className='text-xl'>Generando PDF</p>
				<PulseLoader />
			</div>
		);

	if (instance.error) return <div>Ocurrió un error, refresca la página e intenta de nuevo</div>;

	return <PdfDisplay src={instance.url} />;
};

// Create Document Component
export const MerchantContractPdf = ({ config, merchant }) => {
	// Avoid breaking the string and do not create new line with the same word
	// https://github.com/diegomura/react-pdf/blob/master/packages/textkit/src/engines/wordHyphenation/index.js
	const urlDomain = '\u00AD' + config.url_domain;

	// According to entrance fee amount, maintenance fee varies
	const exemptYears = merchant.entranceFee >= 2500 ? '5' : '2';

	return (
		<Document>
			<Page style={styles.page}>
				<Header config={config} />
				<FooterPageNumber
					acceptConditionsAt={merchant.acceptConditionsAt}
					acceptConditions={merchant.acceptConditions}
				/>
				<Div style={{ paddingLeft: '40', paddingRight: '40' }}>
					<Title>Contrato de adhesión a {urlDomain}</Title>
					<Div style={{ paddingLeft: '20', paddingRight: '20', marginBottom: '20' }}>
						<SubTitle>Marketplace:</SubTitle>
						<P style={{ paddingTop: '2' }}>
							<Capitalize>{urlDomain}</Capitalize> es una Web Marketplace y una marca propiedad de{' '}
							{config.fiscal_data?.name} con CIF {config.fiscal_data?.cif}.
						</P>
						<P style={{ paddingTop: '2' }}>Correo: {config.notifications_email}</P>
						<P style={{ paddingTop: '2' }}>
							Domicilio: {config.fiscal_data?.address} - CP {config.fiscal_data?.zip} (
							{config.fiscal_data?.city}, {config.fiscal_data?.province})
						</P>
						<P style={{ paddingTop: '2' }}>Inscrita en el: {config.fiscal_data?.registration_info}</P>

						<SubTitle>Asociado:</SubTitle>
						<P style={{ paddingTop: '2' }}>{merchant.administrativeBusinessName}</P>
						<P style={{ paddingTop: '2' }}>CIF: {merchant.administrativeVat}</P>
						<P style={{ paddingTop: '2' }}>
							Dirección: {merchant.administrativeAddress1} {merchant.administrativeAddress2 ?? ''}
						</P>
						<P style={{ paddingTop: '2' }}>Localidad: {merchant.administrativeCity}</P>
						<P style={{ paddingTop: '2' }}>Provincia: {merchant.administrativeProvince}</P>
						<P style={{ paddingTop: '2' }}>CP: {merchant.administrativeZip}</P>

						<P>
							En la página web {urlDomain}, se ofrece un servicio de compra de artículos de origen balear
							en la que los Asociados a la Web, tales como los productores, fabricantes o empresas en
							general, ponen a disposición sus productos y servicios de origen balear para que los
							usuarios de la página web puedan adquirirlos.
						</P>
						<P>
							La confirmación mediante el envío por parte del Asociado de la solicitud de adhesión a{' '}
							{urlDomain} así como las informaciones relativas a cualquier operación efectuada a partir
							del número identificador de Asociado serán almacenadas y archivadas por {urlDomain}, tendrán
							fuerza de prueba en caso de litigio entre las partes o terceros, y serán conservadas por{' '}
							{urlDomain} por el tiempo legalmente establecido.
						</P>
						<P>
							En todos los casos, la validación final del proceso de incorporación a {urlDomain} tendrá
							valor de firma y aceptación expresa de todas las condiciones recogidas en el presente
							contrato de adhesión al sitio Web así como en el anexo con el reglamento de régimen interno
							de funcionamiento.
						</P>
						<P>
							Los Asociados autorizados para ofrecer sus productos o servicios en {urlDomain}, cuyas
							ofertas se muestran en la página, son profesionales que tienen una relación contractual con{' '}
							{urlDomain} que se formaliza mediante la aceptación de las siguientes condiciones
						</P>
					</Div>
					<SubTitle>OBLIGACIONES y DERECHOS de las Partes</SubTitle>
					<SubTitle>Altas, bajas y permanencia como Asociado</SubTitle>
					<Div style={{ paddingLeft: '20', paddingRight: '20', marginBottom: '20' }}>
						<ListP>
							El Asociado se da de alta en el propio MarketPlace de forma telemática, conociendo y
							aceptando las condiciones de dicha afiliación que figuran en este documento.
						</ListP>
						<ListP>
							Al aceptar se compromete a cumplir estrictamente todo lo recogido en dichas condiciones, y
							en especial a aceptar las penalizaciones que el incumplimiento de alguna de ellas pudiese
							implicar.
						</ListP>
						<ListP>
							La duración del contrato es de 3 años contados a partir de la fecha de aceptación por parte
							de {urlDomain}. A la finalización del mismo, si ninguna de las partes indica lo contrario
							con un mes de antelación, el contrato será renovado automáticamente por períodos de un año.
						</ListP>
						<ListP>
							El Asociado podrá darse de baja en cualquier momento durante la vigencia del contrato
							avisando con un mes de antelación y sin penalización alguna por ello, no eximiéndole de
							cumplir todas las obligaciones adquiridas con anterioridad, principalmente las contraídas
							con los usuarios o clientes del MarketPlace {urlDomain}
						</ListP>
						<ListP>
							Asimismo, durante el primer año la desvinculación y baja voluntaria como asociado, le da
							derecho a recuperar la cuota de adhesión que abone y que figura en el punto “Forma y
							conceptos de pago”, siempre y cuando no haya sido financiada por una subvención pública.
						</ListP>
					</Div>
					<Div>
						<SubTitle>Calidad</SubTitle>
						<Div style={{ paddingLeft: '20', paddingRight: '20', marginBottom: '20' }}>
							<ListP>
								El Asociado deberá contar con una operativa de control de los procesos productivos, en
								especial de higiene y temperaturas, con registros de la misma, según sistema de control
								de calidad propio.
							</ListP>
							<ListP>
								Especialmente importante es el cumplimiento de la obligatoriedad de comercialización de
								productos o servicios que tengan exclusivamente el carácter de mallorquín, menorquín,
								ibicenco o formenterense, denominados a partir de este momento y en su conjunto, balear.
							</ListP>
							<ListP symbol=''>
								Se entiende por producto o servicio balear aquel que cumple alguno de los siguientes
								requisitos:
							</ListP>
							<SubListP>
								Ha sido producido originariamente o con ingredientes procedentes exclusivamente de
								Baleares y dentro de los límites geográficos del archipiélago Balear o de su entorno
								marítimo.
							</SubListP>
							<SubListP>
								Que, en el caso de que se hubiese tenido que utilizar alguna materia prima originaria de
								un territorio no perteneciente a las Islas Baleares, no suponga más del 50 % del
								producto final terminado.
							</SubListP>
							<SubListP>
								Que, en su defecto, tenga un proceso de elaboración o transformación intransferible, ya
								sea por condicionantes climatológicos, procesos técnicos o protocolos de fabricación,
								incluido entre ellos la utilización de mano de obra radicada en sus límites geográficos,
								que imposibiliten la réplica de facto en el exterior a las Islas Baleares, y que
								supongan al menos el 50% del valor económico del bien a adquirir.
							</SubListP>
							{/** Actualizado el 25/01/2023 */}
							{/* <ListP>
								Asimismo, el Asociado se compromete a contratar y costear auditorías externas de calidad
								con algunas de las consultoras homologadas por {urlDomain}, con la periodicidad
								al menos de 1 cada 3 años, o si se han registrado en un semestre más de 2 faltas
								calificadas como graves o muy graves en el <Italic>“Régimen Interno”</Italic>.
							</ListP>
							<ListP symbol=''>
								El Asociado queda obligado a enviar los informes de las mismas con las valoraciones
								oportunas a {urlDomain} en un plazo no superior a 60 días después de la fecha de
								realización.
							</ListP>
							<ListP>
								<Capitalize>{urlDomain}</Capitalize> pondrá a disposición de los Asociados al
								menos 2 empresas auditoras de calidad homologadas para que realicen las auditorías
								obligatorias establecidas en el anterior punto. Dichas consultoras cumplirán la
								legislación vigente y la reglamentación de los organismos públicos competentes.
							</ListP> */}
							<ListP>
								Asimismo, el Asociado se compromete a mantener informado a {urlDomain} de cuantas
								variaciones o incidencias significativas se produzcan con cualquiera de los conceptos
								recogidos en este apartado de Calidad.
							</ListP>
							<ListP>
								El Asociado facilitará a {urlDomain} la verificación y el control de los procesos que
								garantizan el cumplimiento de este apartado, coordinando conjuntamente Asociado y{' '}
								{urlDomain} los sistemas más apropiados para su realización.
							</ListP>
							{/** Actualizado el 25/01/2023 */}

							<ListP>
								El Asociado está obligado a comunicar a la administración de {urlDomain} todas las actas
								de consumo o calidad alimentaria que levanten las instituciones públicas siempre que
								afecten a la actividad o a la sociedad sujeto de este contrato.
							</ListP>
							<ListP>
								El Asociado deberá presentar en el plazo definido en el{' '}
								<Italic>“Régimen Interno”</Italic> de {urlDomain} los requerimientos sanitarios que sean
								necesarios o la documentación explicativa pertinente ante cualquier incidencia o
								reclamación relacionada con los pedidos de clientes realizados en {urlDomain}.
							</ListP>
						</Div>
					</Div>
					<Div wrap={false}>
						<SubTitle>Embalajes</SubTitle>
						<Div style={{ paddingLeft: '20', paddingRight: '20', marginBottom: '20' }}>
							<ListP>
								El Asociado utilizará embalajes homologados por {urlDomain} en los siguientes casos:
							</ListP>
							<SubListP>
								Obligatorios: cuando la unidad de venta no posea un embalaje estándar del fabricante y
								se entregue aisladamente.
							</SubListP>
							<SubListP symbol=''>
								En el caso de que el producto esté destinado a unirse o consolidarse con otros productos
								de otros fabricantes por la Logística de {urlDomain} en un embalaje común posterior,
								solo será necesaria una bolsa de papel homologada.
							</SubListP>
							<SubListP>
								Optativos: no será necesario utilizar los embalajes definidos por {urlDomain} si el
								fabricante ya tiene embalajes ecológicos que cumplen un 75% de las características
								definidas para los embalajes por {urlDomain}. Para ello tendrá que contar con un
								certificado de homologación emitido por {urlDomain} previamente.
							</SubListP>
							<SubListP>
								En todo caso el Asociado estará obligado a adherir a su envío el precinto o adhesivo que
								sea definido por la administración de {urlDomain} como obligatorio
							</SubListP>
							<ListP>
								El Asociado será el encargado de comprar por su cuenta los embalajes y precintos
								necesarios para realizar los envíos, utilizando para ello cualquiera de los fabricantes
								homologados por {urlDomain}, y mantener un stock de seguridad de dichos artículos para
								sus futuros envíos
							</ListP>
							<ListP>
								El Asociado dispondrá de un precinto o fleje adhesivo con su propia marca o logo de
								fabricante, que servirá de sello o cierre en el caso de que el producto salga ya
								embalado de fábrica y no se consolide en una caja posteriormente por Logística.
							</ListP>
							<ListP>
								El Asociado deberá pagar la parte prorrateada de embalajes utilizados por {urlDomain} en
								los envíos de sus productos. Para ello {urlDomain} comunicará un sistema de cálculo de
								los costes compartidos entre la empresa logística y los distintos asociados, que se
								actualizará periódicamente minimizando al máximo el alcance de los mismos.
							</ListP>
						</Div>
					</Div>
					<Div>
						<SubTitle>
							Comercio y Mantenimiento del Portfolio de Productos y de la información de la Empresa
						</SubTitle>
						<Div style={{ paddingLeft: '20', paddingRight: '20', marginBottom: '20' }}>
							<ListP>
								Será responsabilidad de cada Asociado tener debidamente cumplimentadas y actualizadas
								las parcelas de la Web donde se alojan sus productos y la reseña de su empresa o
								actividad.
							</ListP>
							<ListP>
								Para tener actualizado su espacio en la Web, cada Asociado previo acceso por contraseña,
								podrá incluir, modificar o deshabilitar los productos a la venta.
							</ListP>
							<ListP>
								El propio portal indicará si cumple con las condiciones mínimas de calidad y si tiene
								que modificar o incluir algún aspecto. No obstante, la publicación en la red de la
								petición por parte del fabricante estará sujeta a la aprobación de {urlDomain}, que será
								quien finalmente lo publique una vez revisada la petición.
							</ListP>
							<ListP>
								Cada Asociado podrá indicar producto a producto si está disponible o no para la venta.
								Será obligación suya el mantener actualizada esta información y el no incurrir en datos
								erróneos. El incumplimiento de este punto dará lugar a acciones correctivas por parte de{' '}
								{urlDomain} según se recoge en el reglamento de régimen interno.
							</ListP>
							<ListP>
								El Asociado velará para que el PVP (precio de venta al público) de la totalidad de su
								surtido en {urlDomain} sea al menos tan barato como el más barato que pueda adquirirse
								tanto en cualquier tienda física como online.
							</ListP>
							<ListP>
								Si el Asociado, por las razones que fuese, optase por no incorporar a la Web los
								productos a comercializar de su marca con sus propios medios, {urlDomain} podrá realizar
								las gestiones necesarias previa petición del Asociado. El coste de las mismas se
								establece en tarifa específica aparte.
							</ListP>
							<ListP>
								La mercancía vendida en el Marketplace {urlDomain} será facturada por {urlDomain} al
								consumidor final, siendo abonada la parte que le corresponde al Asociado según las
								condiciones recogidas en el punto “Conceptos y forma de pago”.
							</ListP>
						</Div>
					</Div>
					<Div wrap={false}>
						<SubTitle>FUNCIONAMIENTO MARKETPLACE</SubTitle>
						<ListP>
							<Capitalize>{urlDomain}</Capitalize> proveerá todos los soportes informáticos a través de su
							Web para que:
						</ListP>
						<SubListP>El Asociado pueda dar de alta y modificar su catálogo de productos.</SubListP>
						<SubListP>
							El Asociado cambie precios de venta, disponibilidad o formatos de cada artículo.
						</SubListP>
						<SubListP>
							El Asociado edite su parcela de empresa donde aparezca una sinopsis de la misma y su
							catálogo en la Web de {urlDomain}.
						</SubListP>
						<SubListP>
							Se pueda consultar el estado de los pedidos y las incidencias de los mismos.
						</SubListP>
						<SubListP>
							Se pueda consultar y emitir documentos de pago o facturas, ya sea a los usuarios como a
							Asociados y administración de {urlDomain}.
						</SubListP>
						<SubListP>
							Estén habilitadas estadísticas e informes sobre pedidos, clientes, artículos, visitas,
							entregas, y cualquiera otra información relevante para las partes.
						</SubListP>
						<ListP>
							La forma en que el Asociado podrá darse de alta será telemática, completando todos sus datos
							profesionales y fiscales, y activando sus medios de pago y cobro en la plataforma de pago
							habilitada.
						</ListP>
					</Div>
					<Div>
						<SubTitle>GESTIONES DE ENVÍOS Y LOGÍSTICA</SubTitle>

						<ListP>
							Cada asociado deberá preparar el envío logístico, con los embalajes e información adjunta
							correspondiente, de todos los pedidos que le han sido notificados a través de la plataforma
							en el plazo máximo de 24 horas en día laboral (siendo de aplicación voluntaria a días no
							laborales).
						</ListP>
						<ListP>
							Es responsabilidad de {urlDomain} definir y ofrecer un servicio de recogida/entrega de los
							artículos al Asociado y de la distribución al consumidor final de los pedidos realizados en
							la Web.
						</ListP>
						<ListP>
							Asimismo, {urlDomain} deberá garantizar la calidad del servicio logístico tanto en control
							de mercancía, temperaturas, plazos de entrega, cumplimiento de la legalidad vigente y
							cualquiera otro aspecto relacionado con el transporte, almacenaje y entrega de los pedidos
							realizados en la Web de {urlDomain}.
						</ListP>
						<ListP>
							Es responsabilidad de {urlDomain} la contratación y seguimiento del operador logístico que
							prestará los servicios de entrega y recogida de los productos para el Marketplace.
						</ListP>
						<ListP>
							<Capitalize>{urlDomain}</Capitalize> establecerá un protocolo de funcionamiento de todo el
							proceso logístico que estará a disposición para consultar por el Asociado en cualquier
							momento.
						</ListP>
						<ListP>
							En base al perfil o contenido de la compra (volumen, temperatura, caducidades…), {urlDomain}{' '}
							en colaboración con el operador logístico contratado, establecerá un escandallo de costes
							que la administración de {urlDomain} repercutirá entre cliente final y Asociado.
						</ListP>
						<ListP>
							Dicho escandallo podrá sufrir variaciones por razones operativas, comerciales o de cambios
							en el contenido de los envíos. Los cambios se comunicarán según el plazo establecido en el
							punto “Forma y conceptos de pago”.
						</ListP>
						<ListP>
							En el caso de que algún producto por sus características de producción se defina por el
							Asociado como de entrega diferida (no disponible en 24h.) los gastos asociados a ese envío
							postergado serán repercutidos al Asociado.
						</ListP>
					</Div>
					<Div>
						<SubTitle uppercase>CONCEPTOS Y FORMA DE PAGO ENTRE {urlDomain} Y ASOCIADO</SubTitle>
						<SubTitle>A- POR LA MERCANCÍA COMERCIALIZADA</SubTitle>
						<ListP>
							<Capitalize>{urlDomain}</Capitalize> enviará una factura proforma para que la emita el
							Asociado, que incluirá:
						</ListP>
						<SubListP>
							<Bold>Mercancía servida</Bold> según pedido del cliente.
						</SubListP>
						<SubListP>
							El <Bold>valor</Bold> a facturar <Bold>de la mercancía </Bold>suministrada al consumidor
							final. Dicho importe será el precio de venta al público menos la comisión de
							{urlDomain} que se establece en un{' '}
							<Bold>{merchant.commission}% del precio de venta al público</Bold>.
						</SubListP>
						<SubListP symbol=''>
							Este porcentaje podrá sufrir variaciones de carácter anual, que será oportunamente
							comunicado por {urlDomain} al Asociado con al menos 30 días antes de su aplicación.
						</SubListP>
						<SubListP>
							Prorrateo de los <Bold>embalajes utilizados en pedidos compartidos</Bold>. Precio de
							embalajes y precintos utilizados en caso de que sea necesario. Dichos embalajes serán los
							homologados por {urlDomain}.
						</SubListP>
						<SubListP symbol=''>
							<Capitalize>{urlDomain}</Capitalize> se compromete a informar al menos semestralmente de los
							escandallos utilizados y a reajustar los costes aplicados, ya sea al alza o a la baja. Dicha
							periodicidad podrá acortarse si los precios del mercado así lo justifican.
						</SubListP>
						<SubListP symbol=''>
							Inicialmente, durante los primeros 12 meses de contrato este precio podrá sufrir variaciones
							repercutibles automáticamente, sin previo aviso, toda vez que se irán ajustando los
							distintos formatos de envío según la tipología de los pedidos.
						</SubListP>
						<SubListP>
							El porcentaje de <Bold>transporte y logística</Bold> repercutido al fabricante que se
							calcula una vez imputado lo abonado por el cliente en ese concepto.
						</SubListP>
						<SubListP symbol=''>
							Dichos costes logísticos repercutidos se calcularán en base a su participación porcentual en
							relación a otros asociados en el cómputo total de cada pedido.
						</SubListP>
						<SubListP symbol=''>
							Este coste podrá sufrir variaciones por el cambio en las condiciones logísticas pactadas con
							la empresa logística, así como por las promociones que {urlDomain} realice periódicamente.
						</SubListP>
						<SubListP symbol=''>
							Dichos cambios serán comunicados con al menos 15 días antes de la entrada en vigor
						</SubListP>
						<SubListP>
							Por producto definido de manera permanente por el Asociado como de entrega diferida. Si un
							producto no está disponible en 24 h., los costes relacionados con ese envío serán
							repercutidos en el Asociado.
						</SubListP>
						<ListP>
							Una vez recepcionada la factura, {urlDomain} autorizará el pago a través de la plataforma de
							pago que opera con {urlDomain} y en la que previamente se ha dado de alta el Asociado
						</ListP>
					</Div>
					<Div wrap={false}>
						<SubTitle>B- OTROS PAGOS</SubTitle>
						{merchant.entranceFee ? (
							<>
								<ListP>
									Coincidiendo con <Bold>la ACEPTACIÓN de afiliación por parte del Asociado</Bold>,
									éste deberá abonar las siguientes cantidades:
								</ListP>
								<SubListP>
									Un pago de{' '}
									<Bold>
										<Currency>{merchant.entranceFee}</Currency>
									</Bold>{' '}
									(más IVA) en concepto de cuota de adhesión.
								</SubListP>
								<SubListP>
									Si el asociado abandonara el proyecto durante el primer año por decisión propia
									tendrá derecho a la devolución de la cuota de adhesión abonada siempre y cuando no
									haya sido financiada por una subvención pública.
								</SubListP>
								<ListP>
									Asimismo, durante los primeros {exemptYears} años estarán exentos del pago de la
									cuota de mantenimiento anual establecida en el 1% de las ventas del año anterior
									(con un máximo de 350 Euros más IVA).
								</ListP>
							</>
						) : (
							<>
								<ListP>
									Coincidiendo con <Bold>la ACEPTACIÓN de afiliación</Bold>, el asociado estará exento
									de abonar cantidad alguna en concepto de cuota de adhesión, comprometiéndose a
									cambio a colaborar con productodeaqui.com en el apoyo y difusión del proyecto en las
									redes sociales, promociones o cualesquiera actividades que organice
									productodeaqui.com para publicitar el proyecto.
								</ListP>
								<ListP>
									A partir del segundo año operativo deberá abonar una cuota de mantenimiento anual
									establecida en el 1% de las ventas del año anterior (con un máximo de 350 Euros más
									IVA).
								</ListP>
							</>
						)}
						<SubListP>
							Dicha cuota de mantenimiento podrá variar con carácter anual, y con criterios definidos por{' '}
							{urlDomain}.
						</SubListP>
					</Div>
					<Div>
						<SubTitle>ASPECTOS LEGALES Y JUSRISDICCIÓN</SubTitle>
						<P>
							El Asociado autoriza a utilizar, comunicar o publicitar su nombre, logo, imágenes, productos
							y cualesquiera contenidos que incluya en la web, en medios sociales, publicitarios o
							profesionales, siempre como parte de los trabajos o actividad comercial desarrollados por
							{urlDomain} y recogidos en este contrato. De la misma forma, el Asociado está autorizado a
							usar el logo y nombre de {urlDomain} como parte de su actividad promocional o de marketing
							en cualquiera de las vertientes que estas supongan. Las dos partes estarán sujetas a las
							normas de libros de estilo corporativo y normas de decoro y buenas costumbres que la otra
							parte establezca.
						</P>
						<P>
							El Asociado o empresa asociada a {urlDomain} será responsable de cualquier problema de
							salud, alergia, daño, malestar o cualesquiera otras incidencias que pudieren sucederle al
							Usuario final al ingerir o utilizar productos/servicios que su marca comercialice en el
							Marketplace. El Asociado no podrá exigir responsabilidad alguna a {urlDomain} ante cualquier
							daño o perjuicio producido a terceros, si éste no se debiese o implique una negligencia o
							incumplimiento grave de las obligaciones recogidas en este contrato por parte
							{urlDomain}.
						</P>
						<P>
							<Capitalize>{urlDomain}</Capitalize> aplicará su máxima diligencia para prestar el servicio,
							incluyendo la exactitud, exhaustividad o actualidad de los contenidos y la disponibilidad y
							continuidad del funcionamiento del mismo.
						</P>
						<P>
							En cualquier caso, el asociado entiende que {urlDomain} podría contener errores y omisiones
							que se solucionarán tan pronto sean detectados o comunicados. Por otro lado, el
							{urlDomain} procurará advertir con suficiente antelación de las interrupciones que pudieran
							suceder en el funcionamiento del servicio.
						</P>
						<P>
							En todo caso, {urlDomain} no se hace responsable por el nivel de utilidad y éxito o
							generación de negocio que los asociados hubiesen podido atribuir al mismo.
						</P>
						<P>
							Además, el Asociado se obliga a estar al corriente de pago de todos los seguros de
							responsabilidad civil o de cualquier índole que por ley deba contratar para atender sus
							obligaciones como productor de artículos/servicios destinados al consumo final
						</P>
					</Div>
					<Div wrap={false}>
						<SubTitle>PROPIEDAD INTELECTUAL E INDUSTRIAL</SubTitle>
						<ListP symbol='1-'>
							Todos los derechos sobre el contenido de la Web (textos, imágenes, audio y/o vídeo)
							pertenecen a <Uppercase>{urlDomain}</Uppercase>, salvo aquellos de empresas con las que haya
							firmado el correspondiente contrato para la provisión de contenidos y los que ya sean
							titularidad de los asociados, que están protegidos por las normas nacionales e
							internacionales de propiedad intelectual e industrial.
						</ListP>
						<ListP symbol='2-'>
							El diseño, imágenes, mapas, gráficos, marcas, rótulos, signos distintivos o logos, frames,
							banners, pop ups, el software y los distintos códigos fuente y objeto, “know how” y cuantos
							otros derechos guardan relación con los contenidos del sitio web y los servicios ofertados
							en el mismo, son titularidad de <Uppercase>{urlDomain}</Uppercase>, quien posee
							legítimamente en exclusiva los derechos de explotación sobre los mismos.
						</ListP>
						<ListP symbol=''>
							Los derechos de propiedad intelectual sobre la Web en cuanto a los elementos visuales, los
							signos distintivos, los logos, las marcas, las imágenes, el contenido propio y cualquier
							elemento que conforme dicha Web son titularidad de <Uppercase>{urlDomain}</Uppercase>. El
							ejercicio exclusivo de los derechos de reproducción, distribución, comunicación pública y
							transformación pertenece a <Uppercase>{urlDomain}</Uppercase> y su empresa titular{' '}
							<Uppercase>{config.fiscal_data.name}</Uppercase>
						</ListP>
						<ListP symbol='3-'>
							El Usuario que accede a la Web no puede copiar, modificar, distribuir, transmitir,
							reproducir, publicar, ceder, vender los elementos anteriormente mencionados o crear nuevos
							productos o servicios derivados de la información obtenida.
						</ListP>
						<ListP symbol=''>
							Únicamente queda autorizada la visualización y carga para el uso personal y no comercial del
							Usuario, sin que pueda hacerlo extensiva a terceras personas o entidades.
						</ListP>
						<ListP symbol=''>
							En el caso de gráficos o diseños que aparezcan en la Web cuya titularidad sean de empresas
							colaboradoras de <Uppercase>{urlDomain}</Uppercase> les serán de aplicación, igualmente, las
							presentes condiciones, salvo pacto en contrario.
						</ListP>
						<ListP symbol='4-'>
							Para toda actuación que exceda del cumplimiento del contrato, el usuario necesitará
							autorización por escrito por parte de <Uppercase>{urlDomain}</Uppercase>, quedando prohibido
							al usuario acceder, modificar, visualizar la configuración, estructura y ficheros de los
							servidores propiedad de <Uppercase>{urlDomain}</Uppercase>, asumiendo la responsabilidad
							civil y penal derivada de cualquier incidencia que se pudiera producir en los servidores y
							sistemas de seguridad como consecuencia directa de una actuación negligente o maliciosa por
							su parte. Queda terminantemente prohibida cualquier alteración de la Web o de su contenido
							por parte del Usuario; así como la instalación de enlaces (hiperenlaces, vínculos o
							hipervínculos), links o similares no autorizados previa y expresamente por{' '}
							<Uppercase>{urlDomain}</Uppercase>.
						</ListP>
					</Div>
					<ListP symbol='5-'>
						Se prohíbe el uso contrario a la legislación sobre propiedad intelectual de los servicios
						prestados por <Uppercase>{urlDomain}</Uppercase> y, en particular, de:
					</ListP>
					<SubListP>
						La utilización que resulte contraria a las leyes españolas o que infrinja los derechos de
						terceros.
					</SubListP>
					<SubListP>
						La publicación o la transmisión de cualquier contenido que, a juicio de PRODUCTODEAQUI.COM,
						resulte violento, obsceno, abusivo, ilegal, racial, xenófobo o difamatorio.
					</SubListP>
					<SubListP>
						Los cracks, números de serie de programas o cualquier otro contenido que vulnere derechos de la
						propiedad intelectual de terceros.
					</SubListP>
					<SubListP>
						La recogida y/o utilización de datos personales de otros usuarios sin su consentimiento expreso
						o contraviniendo lo dispuesto en Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo,
						de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al
						tratamiento de datos personales y a la libre circulación de los mismos.
					</SubListP>
					<SubListP>
						La utilización del servidor de correo del dominio y de las direcciones de correo electrónico
						para el envío de correo masivo no deseado.
					</SubListP>
					<ListP symbol=''>
						El usuario tiene toda la responsabilidad sobre el contenido de su web, la información
						transmitida y almacenada, los enlaces de hipertexto, las reivindicaciones de terceros y las
						acciones legales en referencia a propiedad intelectual, derechos de terceros y protección de
						menores.
					</ListP>
					<ListP symbol=''>
						El usuario es responsable respecto a las leyes y reglamentos en vigor y las reglas que tienen
						que ver con el funcionamiento del servicio online, comercio electrónico, derechos de autor,
						mantenimiento del orden público, así como principios universales de uso de Internet.
					</ListP>
					<ListP symbol=''>
						El usuario indemnizará a <Uppercase>{urlDomain}</Uppercase> por los gastos que generara la
						imputación de <Uppercase>{urlDomain}</Uppercase> en alguna causa cuya responsabilidad fuera
						atribuible al usuario, incluidos honorarios y gastos de defensa jurídica, incluso en el caso de
						una decisión judicial no definitiva.
					</ListP>
					<ListP symbol='6-'>
						<Uppercase>{urlDomain}</Uppercase> velará para que los contenidos de su Web no sean
						pornográficos, xenófobos, discriminatorios, racistas, difamatorios o fomenten cualquier género
						de violencia. Del mismo modo procurará evitar cualquier circunstancia que pueda ser perjudicial
						para los Usuarios, derechos de propiedad de terceros o inciten a conductas que puedan ser
						consideradas como ofensa penal.
					</ListP>

					<Div>
						<SubTitle>Modificaciones y nulidad</SubTitle>
						<ListP symbol=''>
							Podremos actualizar los términos y condiciones del servicio en el futuro, así como
							características y funciones propias del mismo.
						</ListP>
						<ListP symbol=''>
							Te informaremos sobre los cambios en los términos y condiciones colocando un aviso en un
							lugar prominente de nuestro sitio web y/o por correo electrónico.
						</ListP>
						<ListP symbol=''>
							Si cualquier cláusula incluida en estos términos y condiciones fuese declarada, total o
							parcialmente, nula o ineficaz, la misma solo afectará a dicha disposición o a la parte de la
							misma que resulte nula o ineficaz. Subsistirán los términos y condiciones en todo lo demás,
							teniendo tal disposición, o la parte de la misma que resultase afectada, por no puesta.
						</ListP>
						<SubTitle>Reclamaciones y acciones derivadas del contrato</SubTitle>
						<ListP symbol=''>
							En caso de controversia, este servicio se somete a la legislación y a los tribunales del
							domicilio del consumidor.
						</ListP>

						<ListP symbol=''>
							Si quien realizara la contratación del servicio no tuviera la consideración legal de
							consumidor, en caso de controversia las partes se someten a los tribunales de Palma de
							Mallorca y a la legislación española.
						</ListP>
						<SubTitle>Atención al cliente y contacto</SubTitle>
						<ListP symbol=''>
							Para cualquier aclaración, incidencia o reclamación, puedes contactar con nosotros mediante:
						</ListP>
						<ListP symbol=''>E-mail: {config.notifications_email}</ListP>
						<ListP symbol=''>
							Dirección postal: {config.fiscal_data?.address} - CP {config.fiscal_data?.zip} (
							{config.fiscal_data?.city}, {config.fiscal_data?.province})
						</ListP>
					</Div>
				</Div>
			</Page>
		</Document>
	);
};

const P = ({ style = {}, children, ...props }) => {
	const updatedStyle = { textIndent: '20', paddingTop: '10', lineHeight: '1.3', ...style };
	return (
		<Text style={updatedStyle} {...props}>
			{children}
		</Text>
	);
};

const Title = ({ style = {}, children, ...props }) => {
	const updatedStyle = { fontSize: '16', textAlign: 'center', color: '#ff515b', marginBottom: '30', ...style };
	return (
		<P style={updatedStyle} {...props}>
			{children}
		</P>
	);
};

const Bold = ({ children, ...props }) => (
	<Text style={{ fontWeight: 600 }} {...props}>
		{children}
	</Text>
);

const Italic = ({ children, ...props }) => (
	<Text style={{ fontStyle: 'italic' }} {...props}>
		{children}
	</Text>
);

const Uppercase = ({ children, ...props }) => (
	<Text style={{ textTransform: 'uppercase' }} {...props}>
		{children}
	</Text>
);

const Capitalize = ({ children, ...props }) => (
	<Text style={{ textTransform: 'capitalize' }} {...props}>
		{children}
	</Text>
);

const SubTitle = ({ style = {}, uppercase = false, children, ...props }) => {
	const updatedStyle = { textIndent: 0, fontWeight: 600, textTransform: uppercase ? 'uppercase' : 'none', ...style };
	return (
		<P style={updatedStyle} {...props}>
			{children}
		</P>
	);
};

const ListP = ({ symbol = '-', children, ...props }) => {
	return (
		<View wrap={false} style={{ display: 'flex', flexDirection: 'row', paddingLeft: 8 }} {...props}>
			<View style={{ width: 12 }}>
				<P style={{ textIndent: 0 }}>{symbol}</P>
			</View>
			<View>
				<P style={{ textIndent: 0 }}>{children}</P>
			</View>
		</View>
	);
};

const SubListP = ({ symbol = '•', children, ...props }) => {
	return (
		<View wrap={false} style={{ display: 'flex', flexDirection: 'row', paddingLeft: 28 }} {...props}>
			<View style={{ width: 8 }}>
				<P style={{ textIndent: 0 }}>{symbol}</P>
			</View>
			<View>
				<P style={{ textIndent: 0 }}>{children}</P>
			</View>
		</View>
	);
};

const Div = ({ style, children, ...props }) => {
	return (
		<View style={style} {...props}>
			{children}
		</View>
	);
};

const Header = ({ config }) => (
	<View fixed>
		<View render={() => <View style={{ backgroundColor: '#ff515b', width: '100%', height: '15' }} />} />
		<Image
			src={`${config.statics}/mp/logo.png`}
			style={{ width: 120, marginLeft: 'auto', marginTop: 20, marginRight: 30 }}
		/>
	</View>
);

const FooterPageNumber = ({ acceptConditionsAt, acceptConditions }) => {
	if (!acceptConditionsAt || !acceptConditions) {
		acceptConditionsAt = new Date().toISOString();
	}

	return (
		<View
			style={{
				position: 'absolute',
				bottom: '0px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				marginBottom: '40',
				width: '100%',
				paddingLeft: '40',
				paddingRight: '40',
			}}
			fixed
		>
			<Text style={{}} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
			<Text style={{}}>{`${formatDate(acceptConditionsAt)}, ${formatTime(acceptConditionsAt)}`}</Text>
		</View>
	);
};

export default PdfModal;
