import React from 'react';
import { Navigate } from 'react-router-dom';

import EmptyLayout from './EmptyLayout';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

import useAuth from 'hooks/use-auth';
import ScrollTopArrow from 'components/ScrollTopArrow';
import VersionChecker from 'components/VersionChecker';

const BaseLayout = ({ children }) => {
	const { isLoggedIn, loggedInStatus } = useAuth();
	if (isLoggedIn === loggedInStatus.no) return <Navigate to='/login' replace />;

	return (
		<EmptyLayout>
			<Header />
			<div className='flex flex-col md:flex-row flex-1 h-auto'>
				<Sidebar />
				<div className='flex-1 p-5 w-full'>
					<div className='w-full m-5 max-w-6xl mx-auto'>{children}</div>
				</div>
			</div>
			<ScrollTopArrow />
			<VersionChecker />
		</EmptyLayout>
	);
};

export default BaseLayout;
