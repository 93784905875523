import { useToast } from 'components/Toast';
import webhook from 'lib/webhook.js';
import useRevalidateCategory from './revalidateCategory';

const getProductSlugs = (product, locales) => {
	const productSlugs = locales.map(locale => {
		const handle = product.localeHandles.find(localeHandle => {
			return localeHandle.locale === locale;
		});
		if (handle) {
			return `/${locale}/${handle.text}`;
		}

		const defaultHandle = product.localeHandles.find(localeHandle => {
			// This should be the default locale but in the admin panel is Spanish and we want to use Catalan
			// return localeHandle.locale === config.default_locale;
			return localeHandle.locale === 'ca';
		});
		if (defaultHandle) {
			return `/${locale}/${defaultHandle.text}`;
		}

		for (const loc of locales) {
			const handle = product.localeHandles.find(localeHandle => {
				return localeHandle.locale === loc;
			});
			if (handle) {
				return `/${locale}/${handle.text}`;
			}
		}
	});
	return productSlugs;
};

export function useRevalidateProducts() {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const revalidateProducts = (config, products) => {
		let slugs = [];
		products.map(product => {
			const productSlugs = getProductSlugs(product, config.locales);
			slugs = [...slugs, ...productSlugs];
		});

		webhook(config, slugs)
			.then(() => {
				addSuccessMessage('Productos', 'Productos actualizados correctamente');
			})
			.catch(error => {
				addErrorMessage(
					'Productos',
					`Productos actualizado correctamente, pero no se pudieron revalidar en la tienda: "${error.message}"`
				);
			});
	};
	return revalidateProducts;
}

export function useRevalidateProduct() {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const revalidateCategory = useRevalidateCategory();

	const revalidateProduct = (config, product) => {
		// Revalidate product pages
		const productSlugs = getProductSlugs(product, config.locales);

		webhook(config, productSlugs)
			.then(() => {
				addSuccessMessage('Producto', `${product.title} actualizado correctamente`);
			})
			.catch(error => {
				addErrorMessage(
					'Producto',
					`${product.title} actualizado correctamente, pero no se pudo revalidar en la tienda: "${error.message}"`
				);
			});

		// Revalidate merchant pages
		const merchantSlugs = [
			`/ca/asociados/${product.Merchant.slug}`,
			`/es/asociados/${product.Merchant.slug}`,
			`/en/asociados/${product.Merchant.slug}`,
			`/de/asociados/${product.Merchant.slug}`,
		];
		webhook(config, merchantSlugs)
			.then(() => {})
			.catch(error => {
				addErrorMessage(
					'Asociado',
					`${product.Merchant.commercialName} actualizado correctamente, pero no se pudo revalidar en la tienda: "${error.message}"`
				);
			});

		// Revalidate categories pages
		revalidateCategory(config, product.Category);
	};
	return revalidateProduct;
}
