import Field from 'components/Field';
import {
	useMultipleFileUpload,
	useSetImageAttributes,
	useDeleteMediaFile,
	useLazyMedia,
	useMoveProductImagePosition,
	useMoveMerchantImagePosition,
} from 'hooks/use-media';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';

import Button from './Button';
import InputForm from './InputForm';
import AuthorizationBoundary from './AuthorizationBoundary';
import TranslatorButton from './TranslatorButton';
import useConf from 'hooks/use-conf';
import translatePlainField from 'lib/translatePlainField';
import isSuccessResponse from 'lib/isSuccessResponse';
import translateLocale from 'lib/translateLocale';

export const DropImageArea = ({
	Images = [],
	setImages = () => {},
	dirty = false,
	onChange,
	onDelete,
	multiple = false,
	children,
	disabled = false,
	whileIdleText = 'Pincha o Suelta tus Imágenes aquí',
	className = 'cursor-pointer mt-2',
	description = 'Agregar breve descripción para ayudar el SEO.',
	descriptionClassName = 'italic text-sm font-normal mr-12',
	setUploading = () => {},
	withAlts = true,
	path,
	MerchantId,
	ProductId,
	galleryType = '',
	disabledMessage,
	specifications,
	showRequiredIcon = false,
	previewStyle = 'aspect-3/2 object-cover',
	boxStyle = 'w-2/3',
	setTranslatorModal,
	fromLocale,
	setFromLocale,
	onTranslateImage,
	...props
}) => {
	const { locale, user, logOut } = useAuth();
	const isAllowedUser = ['admin', 'merchant'].includes(user.type);
	const { addErrorMessage } = useToast();

	const [uploadFiles, { loading: isUploading }] = useMultipleFileUpload({
		onCompleted: ({ UploadMediaFiles }) => {
			if (isSuccessResponse(UploadMediaFiles, ['MediaFiles'], logOut, addErrorMessage, 'Imagen')) {
				onChange(UploadMediaFiles.MediaFiles);
			}
		},
	});

	// Change upload state only when isUploading mutation change
	useEffect(() => {
		setUploading(isUploading);
	}, [isUploading]);

	const onDrop = useCallback(
		files => {
			if (files.length > 0) {
				uploadFiles({ variables: { files, path, MerchantId, ProductId, galleryType } });
			}
		},
		[uploadFiles, MerchantId, ProductId]
	);

	return (
		<Field
			className={''}
			disabled={disabled || !isAllowedUser}
			containerClassName={disabled || !isAllowedUser ? 'cursor-not-allowed' : ''}
			description={<p className={descriptionClassName}>{description}</p>}
			specifications={
				<>
					{specifications}
					<p>
						Nota: si necesitas ayuda para comprimir imágenes{' '}
						<a
							className='underline text-blue-700 font-semibold'
							href='https://app.tango.us/app/workflow/Pasos-para-manipular-im-genes-b7b688ff5ad945c59a1cd755ff5d429f'
							target='_blank'
							rel='noreferrer nofollow'
						>
							clica aquí
						</a>
					</p>
				</>
			}
			showRequiredIcon={showRequiredIcon}
			{...props}
		>
			<div className={`${className || ''}`} aria-label={props.label}>
				<div
					className={`border-4 border-dashed ${
						disabled || !isAllowedUser ? 'border-gray-300 cursor-not-allowed' : 'border-coral-300'
					} dashed flex w-full flex-col items-center justify-center rounded-lg p-4 text-sm font-bold text-coral-300`}
				>
					{disabled || (!isAllowedUser && Images.length === 0) ? (
						<span className='py-16 text-lg'>
							{isAllowedUser ? disabledMessage : 'No habilitado para subir imágenes'}
						</span>
					) : (
						<DropFileArea
							disabled={disabled || !isAllowedUser}
							onDrop={isAllowedUser && onDrop}
							multiple={multiple}
							isUploading={isUploading}
							noClick={Images?.filter(i => !!i?.id)?.length > 0}
							whileIdleText={whileIdleText}
							isAllowedUser={isAllowedUser}
						>
							{Images?.filter(i => !!i?.id)?.length > 0 && (
								<ImagesGrid
									images={Images?.filter(i => !!i?.id)}
									setImages={setImages}
									dirty={dirty}
									onDelete={onDelete}
									locale={locale}
									withAlts={withAlts}
									required={props.required}
									showRequiredIcon={showRequiredIcon}
									previewStyle={previewStyle}
									boxStyle={boxStyle}
									setTranslatorModal={setTranslatorModal}
									fromLocale={fromLocale}
									setFromLocale={setFromLocale}
									onTranslateImage={onTranslateImage}
									galleryType={galleryType}
								/>
							)}
						</DropFileArea>
					)}
				</div>
				{children}
			</div>
		</Field>
	);
};

// limit 500kb file
const maxFileSize = 500_000;
function fileSizeValidator(file) {
	if (file.size > maxFileSize) {
		// Has to return something different than null or the image is uploaded anyway
		return {
			code: 'file-too-large',
			message: `Imagen: ${file.name} supera límite de 500kb`,
		};
	}

	return null;
}

export const DropFileArea = ({
	disabled,
	children,
	onDrop,
	multiple,
	isUploading,
	noClick = false,
	whileIdleText,
	isAllowedUser,
}) => {
	const { addErrorMessage } = useToast();
	const { getRootProps, getInputProps, fileRejections, isDragActive, open } = useDropzone({
		onDrop,
		noClick: noClick || !isAllowedUser,
		validator: fileSizeValidator,
		accept: 'image/*',
		multiple,
	});

	// Check for invalid images and display error
	useEffect(() => {
		for (const image of fileRejections) {
			switch (image.errors[0].code) {
				case 'too-many-files':
					addErrorMessage('Solo se puede subir un imagen');
					return;
				case 'file-invalid-type':
					addErrorMessage('Formato de imagen no soportado');
					return;
				default:
					addErrorMessage(`${image.errors[0].message}`);
					return;
			}
		}
	}, [fileRejections, addErrorMessage]);

	const handleSelectImageButton = e => {
		e.preventDefault();
		open();
	};

	return (
		<>
			<div
				className={`focus:outline-none w-full text-center text-lg flex flex-col justify-center overflow-auto ${
					disabled || !isAllowedUser ? 'cursor-not-allowed' : ''
				}`}
				{...getRootProps()}
			>
				<input disabled={disabled || !isAllowedUser} {...getInputProps()} />
				{/* Always display images even when dragging new one */}
				{children}
				{isUploading && <p className='py-16'>Subiendo archivos…</p>}
				{isDragActive && isAllowedUser && <p className='py-16'>Suelta tus imágenes aquí</p>}
				{!isDragActive && !noClick && !isUploading && <p className='py-16'>{whileIdleText}</p>}
			</div>
			{/* Button for single image*/}
			{/* don't display when there's an image so user has to delete to upload new one*/}
			<AuthorizationBoundary for={['admin', 'merchant']}>
				{!multiple && !noClick ? (
					<Button
						disabled={disabled}
						onClick={handleSelectImageButton}
						className='mb-1 mt-4 w-full self-center'
					>
						Agregar imagen
					</Button>
				) : null}
				{/* Button for gallery images*/}
				{multiple && (
					<Button
						disabled={disabled}
						onClick={handleSelectImageButton}
						className='mb-1 mt-4 w-full self-center'
					>
						{noClick ? 'Agregar imágenes' : 'Seleccionar imágenes'}
					</Button>
				)}
			</AuthorizationBoundary>
		</>
	);
};

export const ImagesGrid = ({
	images,
	setImages,
	dirty,
	onDelete,
	locale,
	withAlts,
	required,
	showRequiredIcon,
	previewStyle,
	boxStyle,
	setTranslatorModal,
	fromLocale,
	setFromLocale,
	onTranslateImage,
	galleryType,
}) => {
	return (
		<div className={'flex flex-col gap-5 cursor'}>
			{images.map((image, index) => (
				<div key={`ie_${index}`} className='relative'>
					<OrderImagesArrows
						images={images}
						index={index}
						setImages={setImages}
						dirty={dirty}
						galleryType={galleryType}
						locale={locale}
					/>
					<ImageElement
						withAlts={withAlts}
						image={image}
						index={index}
						onDelete={onDelete}
						locale={locale}
						required={required}
						showRequiredIcon={showRequiredIcon}
						previewStyle={previewStyle}
						boxStyle={boxStyle}
						setTranslatorModal={setTranslatorModal}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
						onTranslateImage={onTranslateImage}
					/>
				</div>
			))}
		</div>
	);
};

const OrderImagesArrows = ({ images, index, setImages, dirty, galleryType, locale }) => {
	const { logOut } = useAuth();
	const { addErrorMessage } = useToast();
	const [moveProductImagePosition, { loading: uploadingProductImage }] = useMoveProductImagePosition({
		onCompleted: ({ MoveProductImagePosition }) => {
			if (isSuccessResponse(MoveProductImagePosition, ['MediaFiles'], logOut, addErrorMessage, 'Imagen')) {
				setImages(MoveProductImagePosition.MediaFiles);
				return;
			}
		},
	});
	const [moveMerchantImagePosition, { loading: uploadingMerchantImage }] = useMoveMerchantImagePosition({
		onCompleted: ({ MoveMerchantImagePosition }) => {
			if (isSuccessResponse(MoveMerchantImagePosition, ['MediaFiles'], logOut, addErrorMessage, 'Imagen')) {
				setImages(MoveMerchantImagePosition.MediaFiles);
				return;
			}
		},
	});
	const handleChangePosition = (action, index) => {
		const selectedImage = images[index];

		if (dirty) {
			addErrorMessage('Debes guardar los cambios antes de cambiar el orden de las imágenes');
			return;
		}
		if (galleryType === 'product') {
			moveProductImagePosition({
				variables: {
					ImageId: selectedImage.id,
					action,
					locale,
				},
			});
			return;
		}
		if (galleryType === 'merchant') {
			moveMerchantImagePosition({
				variables: {
					ImageId: selectedImage.id,
					action,
					locale,
				},
			});
			return;
		}
	};

	return (
		images.length > 1 && (
			<AuthorizationBoundary for={['admin', 'merchant']}>
				<div className='right-0 absolute flex flex-col'>
					{index > 0 && (
						<img
							onClick={() => {
								if (!uploadingMerchantImage && !uploadingProductImage) {
									handleChangePosition(-1, index);
								}
							}}
							className={`${
								uploadingMerchantImage || uploadingProductImage
									? 'cursor-not-allowed'
									: 'cursor-pointer'
							} w-10 h-10`}
							title='Subir posición'
							alt='flecha hacia arriba'
							src='/images/square-caret-up-solid.svg'
						/>
					)}
					{index < images.length - 1 && (
						<img
							onClick={() => {
								if (!uploadingMerchantImage && !uploadingProductImage) {
									handleChangePosition(1, index);
								}
							}}
							className={`${
								uploadingMerchantImage || uploadingProductImage
									? 'cursor-not-allowed'
									: 'cursor-pointer'
							} w-10 h-10`}
							title='Bajar posición'
							alt='flecha hacia abajo'
							src='/images/square-caret-down-solid.svg'
						/>
					)}
				</div>
			</AuthorizationBoundary>
		)
	);
};

export const ImageElement = ({
	image,
	index,
	onDelete,
	locale,
	withAlts,
	required,
	showRequiredIcon,
	previewStyle,
	boxStyle,
	setTranslatorModal,
	fromLocale,
	setFromLocale,
	onTranslateImage,
}) => {
	const { logOut } = useAuth();
	const { addInfoMessage, addErrorMessage } = useToast();
	const { config } = useConf();
	const [getLazyMedia] = useLazyMedia();
	const [deleteFile] = useDeleteMediaFile({
		onCompleted: ({ DeleteMediaFile }) => {
			if (DeleteMediaFile.success) {
				addInfoMessage('Imagen', 'Borrada correctamente, recuerda guardar');
				onDelete(image.id);
			} else {
				addErrorMessage('Imagen', `Error: ${DeleteMediaFile.message}`);
			}
		},
	});
	const [setImageAttributes] = useSetImageAttributes();
	const [isImageTranslated, setIsImageTranslated] = useState(false);

	const {
		control,
		register,
		getValues,
		setValue,
		formState: { dirtyFields },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		defaultValues: { imageForm: { title: '', alt: '' } },
	});

	// Reset form so dirtyFields are empty on locale change
	useEffect(() => {
		if (image) {
			// This reset activates the debounce
			setValue('imageForm', { title: image.title || '', alt: image.alt || '' });
		}
	}, [image, locale]);

	const onSubmitTitle = ({ imageForm }) => {
		// onSubmitTitle is always called on first render because of debounce, so only call setImageAttributes if the title field is dirty
		// The debounce is activated by the useEffect that fill the form with the reset method
		if (dirtyFields?.imageForm?.title) {
			const title = imageForm.title;
			image.title = title;
			setImageAttributes({
				variables: { imageId: image.id, attributes: { title }, locale },
				onCompleted: ({ SetImageAttributes }) => {
					if (isSuccessResponse(SetImageAttributes, ['Media'], logOut, addErrorMessage, 'Imagen')) {
						addInfoMessage('Título guardado');
					}
				},
			});
		}
	};

	const onSubmitDescription = ({ imageForm }) => {
		// onSubmitDescription is always called on first render because of debounce, so only call setImageAttributes if the alt field is dirty
		// The debounce is activated by the useEffect that fill the form with the reset method
		if (dirtyFields?.imageForm?.alt) {
			const alt = imageForm.alt;
			image.alt = alt;
			setImageAttributes({
				variables: { imageId: image.id, attributes: { alt }, locale },
				onCompleted: ({ SetImageAttributes }) => {
					if (isSuccessResponse(SetImageAttributes, ['Media'], logOut, addErrorMessage, 'Imagen')) {
						addInfoMessage('Descripción guardada');
					}
				},
			});
		}
	};

	const handleTranslatePlainField = async fieldName => {
		setTranslatorModal(true);
		getLazyMedia({
			variables: { id: image.id, forceLocale: true, locale: fromLocale },
		}).then(async ({ data }) => {
			if (data?.Media) {
				if (!data.Media[fieldName]) {
					addInfoMessage(
						'Traducción',
						`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
					);
					setTranslatorModal(false);
					return;
				}
				const isTranslated = await translatePlainField(
					data.Media?.[fieldName],
					fromLocale,
					setTranslatorModal,
					setValue,
					`imageForm.${fieldName}`,
					addErrorMessage,
					locale,
					config,
					setIsImageTranslated
				);
				if (isTranslated) {
					const translatedImage = getValues('imageForm');
					onTranslateImage(translatedImage, index);
				}
			}
		});
	};

	useEffect(async () => {
		if (isImageTranslated) {
			const imageData = getValues('imageForm');
			setImageAttributes({
				variables: { imageId: image.id, attributes: { title: imageData.title, alt: imageData.alt }, locale },
			}).then(() => {
				addInfoMessage('Traducción de imagen guardada');
				setIsImageTranslated(false);
			});
		}
	}, [isImageTranslated]);

	return (
		<>
			<div className='flex flex-row'>
				<div
					className={`border flex items-center justify-center relative p-2 rounded-md ${boxStyle}`}
					key={image.id}
				>
					<AuthorizationBoundary for={['admin', 'merchant']}>
						<button
							className='absolute top-0 right-0 bg-gray-300 text-gray-700 h-6 w-6 text-2xl font-bold rounded-md flex items-center justify-center overflow-hidden focus:outline-none transition-colors duration-200 focus:bg-coral-500 focus:text-white z-20'
							onClick={e => {
								e.preventDefault();
								if (!window.confirm('¿Confirma borrar la imagen? luego no se puede recuperar')) return;
								onDelete && deleteFile({ variables: { id: image.id } });
							}}
						>
							&times;
						</button>
					</AuthorizationBoundary>
					<img
						src={image.src}
						alt={image.alt}
						title={image.title}
						className={`relative ${previewStyle} block rounded-md w-full`}
					/>
				</div>

				{withAlts && (
					<div className='text-gray-700 flex flex-col w-full ml-10 mr-12'>
						<InputForm
							name='imageForm.title'
							control={control}
							register={register}
							label='Título'
							maxLength={128}
							id={`${image.id}_title`}
							width='w-full'
							placeholder='Título de la imagen'
							labelclass='flex w-1/3'
							required={required}
							showRequiredIcon={showRequiredIcon}
							onSubmit={handleSubmit(onSubmitTitle)}
							getValues={getValues}
							debounce
						>
							<TranslatorButton
								onClick={async () => await handleTranslatePlainField('title')}
								fromLocale={fromLocale}
								setFromLocale={setFromLocale}
							/>
						</InputForm>
						<InputForm
							name='imageForm.alt'
							control={control}
							register={register}
							label='Breve descripción'
							maxLength={256}
							id={`${image.id}_alt`}
							width='w-full'
							placeholder='Breve descripción'
							labelclass='flex w-1/3'
							required={required}
							showRequiredIcon={showRequiredIcon}
							onSubmit={handleSubmit(onSubmitDescription)}
							getValues={getValues}
							debounce
						>
							<TranslatorButton
								onClick={async () => await handleTranslatePlainField('alt')}
								fromLocale={fromLocale}
								setFromLocale={setFromLocale}
							/>
						</InputForm>
					</div>
				)}
			</div>
		</>
	);
};

export default DropImageArea;
